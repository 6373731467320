import { Flex, FlexProps, mdBumps, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { isInceptionDate } from '../../../../../../../domain'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../../../../infrastructure/internationalisation'
import { isISOToday, toDateTime } from '../../../../../../../util/date'
import { usePolicy } from '../../../../policyContext'

const tKey = TKey('components.effective-at')

interface EffectiveAtProps extends FlexProps {
  effectiveAt: string
}

export const EffectiveAt: FunctionComponent<EffectiveAtProps> = (props) => {
  const { t } = useWriskTranslation()
  const { policy } = usePolicy()

  const display = isISOToday(props.effectiveAt) ? (
    <Typo>{t(tKey('immediately'))}</Typo>
  ) : isInceptionDate(policy, props.effectiveAt) ? (
    <Typo>
      {t(tFormats('date.long'), { value: toDateTime(policy.policyDetail.inceptedAt) })}
    </Typo>
  ) : (
    <Typo>{t(tFormats('date.long'), { value: toDateTime(props.effectiveAt) })}</Typo>
  )

  return (
    <Flex p={mdBumps} borderTopWidth={1} {...props}>
      <Typo fontWeight='bold' mr={2}>
        {t(tKey('date'))}
      </Typo>
      {display}
    </Flex>
  )
}
