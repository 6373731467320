import css from '@styled-system/css'
import { isNil, negate } from 'lodash'
import React, { FunctionComponent, useCallback } from 'react'
import styled from 'styled-components'
import { compose, space, variant } from 'styled-system'

import { Grid, GridProps } from '../layout'

export type RadioButtonLayouts = 'default' | 'small'
export type RadioButtonTypes = 'nested' | 'standalone'

export interface RadioButtonProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  layout?: RadioButtonLayouts
  type?: RadioButtonTypes
}

const Component: FunctionComponent<RadioButtonProps> = ({
  children,
  className,
  onChange,
  value,
  name,
  ...props
}) => {
  const onInputChange = useCallback(
    (e) =>
      onChange?.({
        ...e,
        target: {
          ...e.target,
          value,
        },
        currentTarget: {
          ...e.currentTarget,
          value,
        },
      }),
    [onChange, value],
  )

  const id = props.id ?? [name, value].filter(negate(isNil)).join('-')

  return (
    <div className={className} data-testid={props['data-testid']}>
      <input
        {...props}
        id={id}
        type='radio'
        name={name}
        value={value}
        onChange={onInputChange}
        autoComplete={props.autoComplete ?? 'off'}
        autoFocus={props.autoFocus ?? false}
      />
      <label htmlFor={id}>{children}</label>
    </div>
  )
}

export const RadioButton = styled(Component)(
  css({
    position: 'relative',

    label: {
      transition: 'all 250ms ease',
      fontFamily: 'input',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      backgroundColor: 'chalk',
      px: 2,
      ':hover': {
        boxShadow: 'radioHover',
      },
    },

    input: {
      position: 'absolute',
      appearance: 'none',
      width: 0,
      height: 0,
      opacity: 0,
      zIndex: -1,

      ':focus + label': {
        boxShadow: 'radioHover',
      },

      ':checked + label': {
        fontWeight: 'bold',
        boxShadow: 'radioChecked',
        backgroundColor: 'radioCheckedBackground',
        color: 'radioCheckedForeground',
      },
    },
  }),
  variant({
    prop: 'layout',
    scale: 'RadioButton.layouts',
    variants: {
      default: {
        label: {
          py: 3,

          fontSize: '16px',
          lineHeight: '24px',
        },
      },
      small: {
        label: {
          py: 2,

          fontSize: '14px',
          lineHeight: '20px',
        },
      },
    },
  }),
  variant({
    prop: 'type',
    scale: 'RadioButton.type',
    variants: {
      nested: {
        label: {
          boxShadow: 'radioNested',
          borderRadius: 1,
        },
      },
      standalone: {
        label: {
          boxShadow: 'radioStandalone',
          borderRadius: 2,
        },
      },
    },
  }),
  compose(space),
)

RadioButton.defaultProps = {
  layout: 'default',
  type: 'standalone',
}

export const RadioButtonGrid: FunctionComponent<
  Omit<GridProps & { columns: number }, 'color'>
> = ({ children, columns, ...props }) => (
  <Grid
    gridColumnGap={2}
    gridRowGap={2}
    gridAutoFlow='row'
    gridTemplateColumns={`repeat(${columns}, 1fr)`}
    {...props}
  >
    {children}
  </Grid>
)
