import { Flex, FlexAnchor, Icon, mdBumps, Typo } from '@wrisk/ui-components'
import React, { useCallback } from 'react'

import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

interface VoluntaryExcessProps {
  currentVoluntaryExcess: number
  onClick: (excess: number) => void
}

const tKey = TKey('components.voluntary-excess')

export const VoluntaryExcess: React.FC<VoluntaryExcessProps> = ({
  currentVoluntaryExcess,
  onClick,
}) => {
  const { t } = useWriskTranslation()

  const onAnchorClick = useCallback(() => {
    onClick(currentVoluntaryExcess)
  }, [currentVoluntaryExcess, onClick])

  return (
    <Flex variant='raised' width={1} py={2}>
      <FlexAnchor
        width={1}
        variant='inset'
        px={mdBumps}
        py={3}
        onClick={onAnchorClick}
        data-testid='open-excess-modal'
      >
        <Flex flexDirection='column' alignItems='flex-start'>
          <Typo typoSize='xs' mb={1}>
            {t(tKey('header'))}
          </Typo>
          <Typo>{t(tFormats('currency.short'), { amount: currentVoluntaryExcess })}</Typo>
        </Flex>
        <Icon icon='edit' size='tiny' />
      </FlexAnchor>
    </Flex>
  )
}
