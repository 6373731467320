import {
  Box,
  BoxProps,
  Flex,
  HelpFlexAnchor,
  Icon,
  mdBumps,
  SectionContent,
  SectionTitle,
  Typo,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'

const tKey = TKey('components.product-faq')

export const ProductFaqSection: FunctionComponent<BoxProps> = (props) => {
  const { t } = useWriskTranslation()

  const faqTranslations = t(tKey('faqs'), {
    returnObjects: true,
    defaultValue: [],
  }) as { question: string; url: string }[]

  const faqs = faqTranslations.map(({ question, url }) => {
    return (
      <Flex key={question} width={1} flexWrap='wrap' flexDirection='column'>
        <HelpFlexAnchor width={1} px={mdBumps} py={3} variant='inset' href={url}>
          <Flex flexDirection='row' alignItems='center'>
            <Typo>{question}</Typo>
          </Flex>
          <Icon icon='info' size='tiny' />
        </HelpFlexAnchor>
      </Flex>
    )
  })

  return (
    <Box width={1} {...props}>
      <SectionTitle>{t(tKey('header'))}</SectionTitle>
      <SectionContent>
        <Flex width={1} py={2} flexWrap='wrap' variant='raised' flexDirection='column'>
          {faqs}
        </Flex>
      </SectionContent>
    </Box>
  )
}
