import css from '@styled-system/css'
import styled from 'styled-components'
import { color, ColorProps, compose, space, SpaceProps } from 'styled-system'

import { xxlBumps } from '../layout/bumps'

export const PageContainer = styled.section<ColorProps & SpaceProps>(
  css({
    flex: '1 0 auto',
    pb: xxlBumps,
  }),
  compose(color, space),
)
