import { isNil, uniq } from 'lodash'
import { useMemo } from 'react'
import { Control, useFormContext, useWatch } from 'react-hook-form'

import { isPolicyholderWithAccount } from '../../domain'
import {
  AdminCondition,
  AdminPermissionCondition,
  AnonymousCondition,
  FeatureToggleCondition,
  getFeatureToggles,
  InputCondition,
  InputConfig,
  InputValueCondition,
  PolicyCondition,
  SchemeCodeCondition,
  useConfig,
} from '../../state/configuration'
import { usePolicyholder, usePrincipal } from '../authentication'
import { useMaybePolicy } from '../product/policy/policyContext'
import { useScheme } from '../product/productContext'
import { useMaybeProposal } from '../product/proposal/proposalContext'

export const getInputConditionNames = (inputs: InputConfig[]) =>
  inputs
    .flatMap((it) => it.conditional?.conditions ?? [])
    .map((it) => (it as InputValueCondition).name)

export const useFieldWatch = ({
  control,
  names,
}: {
  control: Control
  names: string[]
}) => {
  const uniqueNames = uniq(names)

  const values = useWatch({
    control,
    name: uniqueNames,
  })

  return useMemo(
    () =>
      uniqueNames.reduce(
        (acc: Record<string, unknown>, it, index) => ({
          ...acc,
          [it]: values[index],
        }),
        {},
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    values,
  )
}

export const useInputValueCondition = (inputs: InputConfig[]) => {
  const { control } = useFormContext()

  const conditionalValues = useFieldWatch({
    names: getInputConditionNames(inputs),
    control,
  })

  return useMemo(
    () => (condition: InputValueCondition) => {
      // prettier-ignore
      return isNil(condition.isDefined)
        ? condition.not
          ? !condition.values?.some(
          (value) => value === conditionalValues[condition.name]) ?? false
          : condition.values?.some(
          (value) => value === conditionalValues[condition.name]) ?? false
        : condition.isDefined === !isNil(conditionalValues[condition.name])
    },
    [conditionalValues],
  )
}

export const useFeatureToggleCondition = () => {
  const toggles = useConfig(getFeatureToggles)
  return useMemo(
    () => (condition: FeatureToggleCondition) =>
      toggles[condition.featureToggle] ?? false,
    [toggles],
  )
}

export const useAnonymousCondition = () => {
  const { policyholder } = usePolicyholder()
  return useMemo(
    () => (condition: AnonymousCondition) =>
      isPolicyholderWithAccount(policyholder) !== condition.isAnonymousOnly,
    [policyholder],
  )
}
export const usePolicyCondition = () => {
  const policy = useMaybePolicy()
  const proposal = useMaybeProposal()

  return useMemo(
    () => (condition: PolicyCondition) => {
      return condition.isPolicy === (Boolean(policy) || Boolean(proposal?.sourcePolicy))
    },
    [policy, proposal],
  )
}

export const useSchemeCodeCondition = () => {
  const scheme = useScheme()

  return useMemo(
    () => (condition: SchemeCodeCondition) => {
      return condition.schemeCodes.includes(scheme?.schemeCode ?? '')
    },
    [scheme],
  )
}

export const useAdminCondition = () => {
  const { isAdmin } = usePrincipal()
  return useMemo(
    () => (adminCondition: AdminCondition) => isAdmin === adminCondition.isAdmin,
    [isAdmin],
  )
}

export const useAdminPermissionCondition = () => {
  const principal = usePrincipal()

  return useMemo(
    () => (condition: AdminPermissionCondition) => {
      if (!principal.isAdmin) return condition.default ?? false

      const permissions = principal.user.permissions

      const hasPermissionsSatisfied = condition.hasPermissions
        ? condition.hasPermissions.every((permission) => permissions.includes(permission))
        : true

      const notHasPermissionsSatisfied = condition.notHasPermissions
        ? condition.notHasPermissions.every(
            (permission) => !permissions.includes(permission),
          )
        : true

      return hasPermissionsSatisfied && notHasPermissionsSatisfied
    },
    [principal],
  )
}

const useConditions = (inputs: InputConfig[] = []) => {
  const inputValueCondition = useInputValueCondition(inputs)
  const featureToggleCondition = useFeatureToggleCondition()
  const anonymousCondition = useAnonymousCondition()
  const policyCondition = usePolicyCondition()
  const schemeCodeCondition = useSchemeCodeCondition()
  const adminCondition = useAdminCondition()
  const adminPermissionCondition = useAdminPermissionCondition()

  return useMemo(
    () => (it: InputCondition) => {
      switch (it.type) {
        case 'input':
          return inputValueCondition(it)
        case 'featureToggle':
          return featureToggleCondition(it)
        case 'anonymous':
          return anonymousCondition(it)
        case 'policy':
          return policyCondition(it)
        case 'schemeCode':
          return schemeCodeCondition(it)
        case 'admin':
          return adminCondition(it)
        case 'adminPermission':
          return adminPermissionCondition(it)
        default:
          return false
      }
    },
    [
      inputValueCondition,
      featureToggleCondition,
      anonymousCondition,
      policyCondition,
      schemeCodeCondition,
      adminCondition,
      adminPermissionCondition,
    ],
  )
}

export default useConditions
