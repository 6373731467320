import { Box, Form } from '@wrisk/ui-components'
import React, { FunctionComponent, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { TExtends } from '../../infrastructure/internationalisation'
import { ActionBar } from '../organisms/form'
import { FormErrorsPortal, FormFooter, FormSections } from './components'
import { defaultOptions, ERROR_MESSAGE_CLASS, INPUT_SECTION_CLASS } from './options'
import { FormBuilderProps } from './types'

export const FormErrors: FunctionComponent<FormBuilderProps> = ({
  id,
  footerText,
  data,
  inputs,
  onSubmit,
  onSkip,
  loading,
  options = {},
  tKey,
  t,
  ...props
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const finalOptions = useMemo(() => ({ ...defaultOptions, ...options }), [])

  const form = useForm({
    defaultValues: data,
    shouldUnregister: finalOptions.shouldUnregister,
  })

  return (
    <Box width={1} {...props}>
      <FormProvider {...form}>
        <Form formId={id} onSubmit={form.handleSubmit(onSubmit)}>
          <FormSections
            inputs={inputs}
            options={finalOptions}
            sectionClassName={INPUT_SECTION_CLASS}
            errorClassName={ERROR_MESSAGE_CLASS}
            tKey={TExtends(tKey, 'inputs')}
            t={t}
          />
          <FormFooter tKey={tKey} tName={footerText} t={t} />
          {/* eslint-disable-next-line react/jsx-no-undef */}
          <ActionBar tKey={tKey} loading={loading} onDismiss={onSkip} />
        </Form>
        <FormErrorsPortal
          tKey={tKey}
          t={t}
          scrollToClass={INPUT_SECTION_CLASS}
          errorClass={ERROR_MESSAGE_CLASS}
        />
      </FormProvider>
    </Box>
  )
}
