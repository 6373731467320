import {
  AnimatedPage,
  Flex,
  FlexAnchor,
  Header,
  HeaderTitle,
  Icon,
  LinkAnchor,
  MainSection,
  PrimaryButton,
  SideSection,
  StickySidebar,
  WideContainer,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useState } from 'react'
import { generatePath, Link, useNavigate } from 'react-router-dom'

import {
  coverDifferencesToDisclosureChange,
  getCoreAsset,
  getPolicyVersion,
} from '../../../../domain'
import { useOptionalCovers } from '../../../../hooks/covers'
import { useErrorHandlingAsyncCallback } from '../../../../hooks/errors'
import {
  tDefaults,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { Page, useUpButton } from '../../../../infrastructure/routing'
import { usePrincipal } from '../../../authentication'
import { AddOnSelector } from '../../../organisms/covers'
import { Footer } from '../../../templates'
import { useProduct } from '../../productContext'
import { usePolicy } from '../policyContext'
import { AddOnsPath } from './AddOnsPath'
import { PolicyAddOnsSidebar } from './PolicyAddOnsSidebar'

interface PolicyAddOnsPageProps {
  destination: string
  parent: Page
}

const tKey = TKey('pages.policy-add-ons')

export const PolicyAddOnsPage: FunctionComponent<PolicyAddOnsPageProps> = ({
  parent,
}) => {
  const { t } = useWriskTranslation()

  const navigate = useNavigate()

  const upButton = useUpButton(parent)
  const { apiClient } = usePrincipal()
  const { policy } = usePolicy()
  const { product } = useProduct()

  const { quote } = getPolicyVersion(policy)
  const { assetCode } = getCoreAsset(quote)

  const originalCovers = useOptionalCovers(quote, product)

  const [updatedCovers, setUpdatedCovers] = useState(originalCovers)

  const { loading, execute: onContinue } = useErrorHandlingAsyncCallback(async () => {
    const changes = coverDifferencesToDisclosureChange(
      assetCode,
      updatedCovers,
      originalCovers,
    )

    const { adjustmentId } = await apiClient.createPolicyAdjustment({
      policyId: policy.policyId,
      adjustmentType: 'MTA',
      changes,
    })

    changes.length
      ? navigate(generatePath(AddOnsPath.REVIEW, { adjustmentId }))
      : navigate(parent.url)
  })

  // TODO (MK): Use FullPage when either using new react-router or when FullPage supports callbacks
  return (
    <AnimatedPage pageId='add-ons-page' footer={<Footer />}>
      <WideContainer>
        <Header>
          <Flex width={1}>
            <FlexAnchor
              fontFamily='buttons'
              mb={10}
              as={Link}
              to={upButton?.to ?? '/'}
              data-testid='link-back'
            >
              <Icon size='small' pl={2} icon='chevronRight' rotate={180} />
              <LinkAnchor variant='standalone' as='span' typoSize='sm'>
                {upButton?.text || 'Back'}
              </LinkAnchor>
            </FlexAnchor>
          </Flex>
          <HeaderTitle>{t(tKey('header'))}</HeaderTitle>
        </Header>
      </WideContainer>
      <WideContainer variant='sidebar'>
        <StickySidebar>
          <SideSection>
            <PolicyAddOnsSidebar
              optionalCovers={updatedCovers}
              onContinue={onContinue}
              loading={loading}
            />
          </SideSection>
        </StickySidebar>
        <MainSection>
          <AddOnSelector covers={updatedCovers} onChange={setUpdatedCovers} />

          <PolicyAddOnsSidebar
            displayOn='mobile'
            optionalCovers={updatedCovers}
            loading={loading}
            onContinue={onContinue}
          />
          <Flex displayOn='desktop' width={1} mt={5} data-testid='button-continue'>
            <PrimaryButton
              layout='fixed'
              loading={loading}
              onClick={onContinue}
              data-testid='button-continue'
            >
              {t([tKey('actions.continue'), tDefaults('actions.continue')])}
            </PrimaryButton>
          </Flex>
        </MainSection>
      </WideContainer>
    </AnimatedPage>
  )
}
