import React from 'react'

import {
  PolicyAdjustmentChange,
  PolicyChangeType,
  PolicyVersion,
} from '../../../../../../../../domain'
import AddOnChangesItem from './AddOnChangesItem'
import ExcessChangesItem from './ExcessChangesItem'

interface Props {
  change: PolicyAdjustmentChange
  updatedPolicyVersion: PolicyVersion
}

export const PolicyAdjustmentChangesItem: React.FC<Props> = ({
  change,
  updatedPolicyVersion,
}) => {
  switch (change.changeType) {
    case PolicyChangeType.SELECT_VOLUNTARY_EXCESS:
      return <ExcessChangesItem updatedPolicyVersion={updatedPolicyVersion} />
    case PolicyChangeType.ADD_COVER:
    case PolicyChangeType.REMOVE_COVER:
      return <AddOnChangesItem change={change} />
    default:
      return null
  }
}
