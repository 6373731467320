import {
  ExpandableSection,
  PremiumBreakdown,
  PremiumLineItem,
  PremiumSummary,
  smBumps,
} from '@wrisk/ui-components'
import { TFunction } from 'i18next'
import React, { FunctionComponent, useMemo } from 'react'

import { inForce, PriceOption } from '../../../domain'
import { QuotePremiums } from '../../../hooks/premiums'
import {
  tCovers,
  tFormats,
  TKeyBuilder,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { toDateTime } from '../../../util/date'
import { IPT } from '../product'

export interface StandardAdjustedPremiumProps {
  currentPremiums: QuotePremiums
  previousPremiums?: QuotePremiums
  discount?: PriceOption
  expanded?: boolean
  tKey: TKeyBuilder
}

export const StandardAdjustedPremium: FunctionComponent<StandardAdjustedPremiumProps> = ({
  currentPremiums,
  previousPremiums,
  discount,
  expanded,
  tKey,
}) => {
  const { t } = useWriskTranslation()

  const subheader = inForce(discount)
    ? t(tKey('promotion'), {
        date: t(tFormats('date.long'), { value: toDateTime(discount?.endAt) }),
      })
    : undefined

  const header = (
    <PremiumSummary
      header={t<string>(tKey('header'))}
      subheader={subheader}
      currentAmount={t(tFormats('currency.long'), {
        amount: currentPremiums.totals.discounted,
      })}
      previousAmount={
        previousPremiums &&
        t<string>(tFormats('currency.long'), {
          amount: previousPremiums.totals.discounted,
        })
      }
    />
  )

  const lineItems = useMemo<PremiumLineItem[]>(
    () => [
      ...coreLineItems(t, tKey, currentPremiums, previousPremiums),
      ...discountLineItems(t, tKey, currentPremiums, previousPremiums),
      ...optionalLineItems(t, currentPremiums, previousPremiums),
    ],
    [currentPremiums, previousPremiums, t, tKey],
  )

  return (
    <ExpandableSection header={header} open={expanded} reversed>
      <PremiumBreakdown lineItems={lineItems} ipt={<IPT />} mx={smBumps} />
    </ExpandableSection>
  )
}

const coreLineItems = (
  t: TFunction,
  tKey: TKeyBuilder,
  currentPremiums: QuotePremiums,
  previousPremiums?: QuotePremiums,
) => [
  {
    header: t(tKey('core')),
    currentAmount: t(tFormats('currency.long'), {
      amount: currentPremiums.breakdown.main,
    }),
    previousAmount:
      previousPremiums &&
      t(tFormats('currency.long'), { amount: previousPremiums.breakdown.main }),
  },
]

const discountLineItems = (
  t: TFunction,
  tKey: TKeyBuilder,
  currentPremiums: QuotePremiums,
  previousPremiums?: QuotePremiums | undefined,
) =>
  currentPremiums.breakdown.discount
    ? [
        {
          header: t(
            tKey(
              currentPremiums.breakdown.main - currentPremiums.breakdown.discount < 0.001
                ? 'discount.full'
                : 'discount.partial',
            ),
          ),
          currentAmount: t(tFormats('currency.long'), {
            amount: -currentPremiums.breakdown.discount,
          }),
          previousAmount:
            previousPremiums &&
            t(tFormats('currency.long'), {
              amount: -previousPremiums.breakdown.discount,
            }),
        },
      ]
    : []

const optionalLineItems = (
  t: TFunction,
  currentPremiums: QuotePremiums,
  previousPremiums?: QuotePremiums,
) =>
  currentPremiums.optional.map((current) => {
    const previousAmount = previousPremiums?.optional.find(
      (previous) => previous.coverCode === current.coverCode,
    )

    return {
      header: t(tCovers(current.coverCode, 'name')),
      currentAmount: t(tFormats('currency.long'), { amount: current.premium }),
      previousAmount:
        previousAmount &&
        t(tFormats('currency.long'), { amount: previousAmount.premium }),
    }
  })
