import {
  CustomerStatementLineItem,
  LineItemGroup,
  LineItemStatus,
  LineItemSubType,
  LineItemType,
} from './types'

export const isPendingLineItem = (lineItem: CustomerStatementLineItem) =>
  lineItem.status === LineItemStatus.PENDING

export const isInvoiceLineItem = (lineItem: CustomerStatementLineItem) =>
  lineItem.group === LineItemGroup.INVOICE

export const isJournalLineItem = (lineItem: CustomerStatementLineItem) =>
  [LineItemType.DEBIT_JOURNAL, LineItemType.CREDIT_JOURNAL].includes(lineItem.type)

export const isMtaLineItem = (lineItem: CustomerStatementLineItem) =>
  lineItem.subType === LineItemSubType.MTA
