import { Box, ModalHeader, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import { TKey, useWriskTranslation } from '../../infrastructure/internationalisation'
import { Chat } from './Chat'

const tKey = TKey('components.in-renewal-change-blocked-modal')

export const InRenewalMtaBlockedModal: FunctionComponent<unknown> = () => {
  const { t } = useWriskTranslation()

  return (
    <Box>
      <ModalHeader header={t(tKey('header'))} />
      <Typo>
        <Trans t={t} i18nKey={tKey('body')} components={{ Chat: <Chat /> }} />
      </Typo>
    </Box>
  )
}
