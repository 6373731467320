import {
  Checkbox,
  Flex,
  FlexAnchor,
  LinkAnchor,
  mdBumps,
  PrimaryButton,
  Typo,
  xlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent, PropsWithChildren, useCallback, useState } from 'react'
import { Trans } from 'react-i18next'

import {
  TKey,
  useWriskTranslation,
} from '../../../../../../infrastructure/internationalisation'
import { ProductConfig } from '../../../../../../state/configuration'

const tKey = TKey('components.start-checkout')

const TermsOfBusinessLink: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { t } = useWriskTranslation()
  return (
    <LinkAnchor href={t<string>('links.business-terms')} target='_blank'>
      {children}
    </LinkAnchor>
  )
}

const PrivacyPolicyLink: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { t } = useWriskTranslation()
  return (
    <LinkAnchor href={t<string>('links.privacy-policy')} target='_blank'>
      {children}
    </LinkAnchor>
  )
}

export const StartCheckoutSection: FunctionComponent<{
  product: ProductConfig
  onConfirm: () => void
  loading: boolean
}> = ({ product, onConfirm, loading }) => {
  const { t } = useWriskTranslation()

  const [checkoutChecklist, setCheckoutChecklist] = useState<Record<string, boolean>>(
    product.checkoutChecklist?.reduce((acc, it) => ({ ...acc, [it]: false }), {}) ?? {},
  )

  const onChange = useCallback(
    (id: string) => (checked: boolean) =>
      setCheckoutChecklist((current) => ({ ...current, [id]: checked })),
    [setCheckoutChecklist],
  )

  const confirmSections = Object.entries(checkoutChecklist).map(([id, checked]) => (
    <FlexAnchor
      key={id}
      as='label'
      htmlFor={id}
      backgroundColor='chalk'
      justifyContent='flex-start'
      width={1}
      variant='inset'
      px={mdBumps}
      py={3}
    >
      <Checkbox id={id} mr={4} value={checked} onChange={onChange(id)} />
      <Typo>
        <Trans
          t={t}
          i18nKey={tKey('agreements', id)}
          components={{
            TermsOfBusinessLink: <TermsOfBusinessLink />,
            PrivacyPolicyLink: <PrivacyPolicyLink />,
          }}
        />
      </Typo>
    </FlexAnchor>
  ))

  return (
    <>
      {confirmSections.length > 0 && (
        <Flex
          width={1}
          py={2}
          mb={xlBumps}
          flexWrap='wrap'
          variant='raised'
          flexDirection='column'
        >
          {confirmSections}
        </Flex>
      )}

      <PrimaryButton
        data-testid='button-continue'
        layout='fixed'
        onClick={onConfirm}
        loading={loading}
        disabled={!Object.values(checkoutChecklist).every(Boolean)}
      >
        {t(tKey('actions.continue'))}
      </PrimaryButton>
    </>
  )
}
