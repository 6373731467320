import {
  PrimaryButton,
  Section,
  SectionContent,
  SectionDescription,
  SectionTitle,
  xxlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  CustomerPayment,
  getUsablePaymentMethod,
  PaymentMethod,
  PaymentMethodType,
} from '../../../../../domain'
import { useApiErrorHandlingAsyncCallback } from '../../../../../hooks/auth'
import { useCheckoutStripe, useSetupStripe } from '../../../../../hooks/stripe'
import {
  TKey,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'
import { usePrincipal } from '../../../../authentication'
import { FullPage } from '../../../../templates'
import { useProposal } from '../../proposalContext'
import { CheckoutPath } from '../CheckoutPath'
import { PaymentDue } from './PaymentDue'

interface CheckoutBACSProps {
  customerPayment: CustomerPayment | undefined
  paymentMethods: PaymentMethod[]
}

const tKey = TKey('pages.checkout-card')

export const CheckoutCard: FunctionComponent<CheckoutBACSProps> = ({
  customerPayment,
  paymentMethods,
}) => {
  const { t } = useWriskTranslation()

  const { apiClient } = usePrincipal()
  const { proposal, setProposal } = useProposal()

  const paymentMethod = getUsablePaymentMethod(paymentMethods, PaymentMethodType.CARD)

  const onCheckoutStripe = useCheckoutStripe({
    successPath: [location.pathname, CheckoutPath.PAYMENT_RESULT_CALLBACK].join('/'),
    cancelPath: location.pathname,
  })

  const onSetupStripe = useSetupStripe({
    successPath: location.pathname,
    cancelPath: location.pathname,
    clientReference: proposal.proposalCode,
  })

  const onCheckout = useApiErrorHandlingAsyncCallback(async () =>
    customerPayment?.amount
      ? await onCheckoutStripe.execute(customerPayment.paymentId)
      : paymentMethod
      ? setProposal(await apiClient.bindProposal(proposal.proposalCode))
      : await onSetupStripe.execute(),
  )

  const paymentDescription = customerPayment?.amount
    ? t(tKey('payment-due', 'subheader', 'pay'), { defaultValue: null })
    : paymentMethod
    ? t(tKey('payment-due', 'subheader', 'bind'), { defaultValue: null })
    : t(tKey('payment-due', 'subheader', 'setup'), { defaultValue: null })

  const buttonDescription = customerPayment?.amount
    ? t(tKey('actions', 'pay'))
    : paymentMethod
    ? t(tKey('actions', 'bind'))
    : t(tKey('actions', 'setup'))

  return (
    <FullPage pageId='checkout-page' header={t(tKey('header'))}>
      <Section width={1} mb={xxlBumps}>
        <SectionTitle>{t(tKey('payment-due', 'header'))}</SectionTitle>
        {paymentDescription && (
          <SectionDescription>{paymentDescription}</SectionDescription>
        )}
        <SectionContent>
          <PaymentDue payment={customerPayment} proposal={proposal} />
        </SectionContent>
      </Section>

      <PrimaryButton
        data-testid='bind-proposal'
        layout='fixed'
        onClick={onCheckout.execute}
        loading={onCheckout.loading}
      >
        {buttonDescription}
      </PrimaryButton>
    </FullPage>
  )
}
