import { i18n } from 'i18next'
import { compact } from 'lodash'
import { Duration } from 'luxon'

import { Policyholder, Profile, Specification } from '../../domain'
import { tDefaults } from './keys'

export const createInternationalisedFormats = (instance: i18n) => {
  instance.services.formatter?.add('duration', (value: string | undefined) => {
    return value ? Duration.fromISO(value).toHuman() : '$$$'
  })

  instance.services.formatter?.add(
    'getPolicyholderName',
    (value: Policyholder | undefined) => {
      return value?.firstName ?? instance.t(tDefaults('policyholder.name'))
    },
  )

  instance.services.formatter?.add(
    'getProfileName',
    ({ data: { firstName, lastName } }: Profile) => {
      return (
        compact([firstName, lastName]).join(' ') || instance.t(tDefaults('profile.name'))
      )
    },
  )

  instance.services.formatter?.add(
    'getProfileFirstName',
    ({ data: { firstName } }: Profile) => {
      return firstName ?? instance.t(tDefaults('profile.name'))
    },
  )

  instance.services.formatter?.add(
    'getVehicleName',
    ({ make, model, trim }: Specification) => {
      return compact([make, model, trim]).join(' ')
    },
  )

  return instance
}
