import { SectionProps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { getPolicyVersion } from '../../../../domain'
import { usePolicyExcessAdjustment } from '../../../../hooks/adjustments/policy'
import { useIsMtaAllowed } from '../../../../hooks/adjustments/policy/renewal'
import { ExcessSection } from '../../../organisms/excess'
import { useProduct } from '../../productContext'
import { usePolicy } from '../policyContext'

export const PolicyExcessSection: FunctionComponent<SectionProps> = (props) => {
  const { policy } = usePolicy()
  const isMtaAllowed = useIsMtaAllowed()
  const { product } = useProduct()

  const { quote } = getPolicyVersion(policy)

  const onSubmitExcess = usePolicyExcessAdjustment()

  return product.excess ? (
    <ExcessSection
      quote={quote}
      onSubmitExcess={onSubmitExcess}
      isMtaAllowed={isMtaAllowed}
      {...props}
    />
  ) : null
}
