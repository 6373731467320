import {
  Flex,
  Icon,
  LinkAnchor,
  Notification,
  NotificationProps,
  Typo,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { getCurrentQuote, PriceOption, RiskProfileStatus } from '../../../domain'
import { useEditProposalCallback } from '../../../hooks/proposal'
import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { useProposal } from '../../product/proposal/proposalContext'

const tKey = TKey('components.promotion-notification')

export interface PromotionNotificationProps extends NotificationProps {
  discount: PriceOption
}

export const PromotionNotification: FunctionComponent<PromotionNotificationProps> = ({
  discount,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const { proposal } = useProposal()

  const quote = getCurrentQuote(proposal)

  const onReviewAnswers = useEditProposalCallback(proposal)

  const mapping = {
    [RiskProfileStatus.REFERRAL]: 'critical',
    [RiskProfileStatus.INSUFFICIENT_ACCURACY]: 'warning',
  }

  const variant = mapping[discount?.ratingStatus]

  return quote.promoCode && discount && variant ? (
    <Notification
      variant={variant}
      type='standalone'
      flexDirection={['column', 'column', 'row']}
      alignItems={'flex-start'}
      {...props}
    >
      <Flex justifyContent='flex-start' mr={[0, 0, 2]} mb={[4, 4, 0]}>
        <Icon display={['none', 'none', 'block']} icon='warning' mr={3} />
        <Typo>{t(tKey(discount.ratingStatus, quote.promoCode, 'notification'))}</Typo>
      </Flex>
      <LinkAnchor variant='standalone' onClick={onReviewAnswers}>
        {t(tKey('actions', 'review'))}
      </LinkAnchor>
    </Notification>
  ) : null
}
