import { Box, Typo, xxlBumps } from '@wrisk/ui-components'
import { TFunction } from 'i18next'
import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import { TKeyBuilder } from '../../../infrastructure/internationalisation'
import { BusinessTermsLink, WriskPrivacyPolicyLink } from '../../organisms/links'

export interface FormFooterProps {
  tName?: string
  tKey: TKeyBuilder
  t: TFunction
}

export const FormFooter: FunctionComponent<FormFooterProps> = ({ t, tKey, tName }) =>
  tName ? (
    <Box mb={xxlBumps}>
      <Typo as='pre' typoSize='sm' color='bodySecondary'>
        <Trans
          t={t}
          i18nKey={tKey('footer')}
          components={{
            BusinessTerms: <BusinessTermsLink typoSize='sm' />,
            WriskPrivacyPolicy: <WriskPrivacyPolicyLink typoSize='sm' />,
          }}
        />
      </Typo>
    </Box>
  ) : null
