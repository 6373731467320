import {
  Box,
  EndorsementNotification,
  SectionProps,
  smBumps,
  Typo,
} from '@wrisk/ui-components'
import React from 'react'

import { getInsuredProfile, Quote } from '../../../domain'
import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { useProduct } from '../../product/productContext'

interface Props extends SectionProps {
  quote: Quote
}

const tKey = TKey('components.endorsements')

export const Endorsements: React.FC<Props> = ({ quote, ...props }) => {
  const { t } = useWriskTranslation()

  const { product } = useProduct()

  const endorsements = product.endorsements
    ? quote.endorsements.filter((it) => product.endorsements?.includes(it.code))
    : []

  return (
    <Box>
      {endorsements.map((it, i) => {
        const data = getInsuredProfile(quote, it.profileCode)?.data

        return data ? (
          <EndorsementNotification
            data-testid={`${it.code} ${it.profileCode}`}
            key={i}
            mt={i && smBumps}
            {...props}
          >
            <Typo>{t(tKey(it.code))}</Typo>
          </EndorsementNotification>
        ) : null
      })}
    </Box>
  )
}
