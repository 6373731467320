import { useMemo } from 'react'

import { usePrincipal } from '../../../../application/authentication'
import { useProposal } from '../../../../application/product/proposal/proposalContext'
import { Data, Proposal } from '../../../../domain'
import { AdjustmentInputConfig } from '../../../../state/configuration'
import { useApiErrorHandlingAsyncCallback } from '../../../auth'
import { hasChanges } from '../helpers'
import { toProposalAdjustment } from './adjustments'
import { toProposalData } from './data'

export const useProposalFormData = (inputs: AdjustmentInputConfig[]) => {
  const { proposal } = useProposal()

  return useMemo(() => {
    const toData = toProposalData(proposal)

    return inputs.reduce(
      (data, input) => ({
        ...data,
        [input.name]: toData(input.adjustment),
      }),
      {},
    )
  }, [inputs, proposal])
}

export const useProposalAdjustment = (
  inputs: AdjustmentInputConfig[],
  checkIdentity: boolean,
  onComplete?: (proposal: Proposal) => Promise<void> | void,
) => {
  const existingData = useProposalFormData(inputs)

  const { apiClient } = usePrincipal()
  const { proposal, setProposal } = useProposal()

  return useApiErrorHandlingAsyncCallback(async (data: Data) => {
    const proposalAdjustment = toProposalAdjustment(
      inputs,
      checkIdentity,
      proposal,
    )(existingData, data)

    const updatedProposal = hasChanges(proposalAdjustment)
      ? await apiClient.updateProposal(proposal.proposalCode, proposalAdjustment)
      : proposal

    setProposal(updatedProposal)
    await onComplete?.(updatedProposal)
  })
}
