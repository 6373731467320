import { Box, Flex, FlexProps, mdBumps, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

const tKey = TKey('components.metered-actual-usage')

export interface MeteredActualUsageProps extends FlexProps {
  policyId: string
}

export const MeteredActualUsage: FunctionComponent<MeteredActualUsageProps> = (props) => {
  const { t } = useWriskTranslation()

  const meteredPremiumItem = {
    amount: 0,
    detail: t(tKey('none')),
  }

  return (
    <Flex width={1} px={5} py={mdBumps} {...props}>
      <Flex flexDirection='column' alignItems='flex-start' mr={2}>
        <Typo typoSize='base' fontWeight='bold'>
          {t(tKey('header'))}
        </Typo>
        <Typo typoSize='xs' mt={1}>
          {meteredPremiumItem?.detail}&nbsp;
        </Typo>
      </Flex>

      <Box id='meteredAmount'>
        <Typo typoSize='md' fontWeight='bold'>
          {t(tFormats('currency.long'), { amount: meteredPremiumItem.amount })}
        </Typo>
      </Box>
    </Flex>
  )
}
