import { DateFeedbackInput } from '@wrisk/ui-components'
import * as React from 'react'
import { FunctionComponent } from 'react'

import { FormInputProps } from '../types'

export const DateFormInput: FunctionComponent<FormInputProps<string>> = ({
  value,
  onChange,
}) => <DateFeedbackInput error={undefined} onChange={onChange} initialValue={value} />
