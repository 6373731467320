import { smBumps, useModal, xlBumps, xxlBumps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { getCurrentQuote } from '../../../../../domain'
import { useCheckoutBind } from '../../../../../hooks/checkout'
import { useEditProposalCallback } from '../../../../../hooks/proposal'
import {
  TKey,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'
import { YourCoverSection } from '../../../../organisms/covers'
import { ProposalDocumentsSection } from '../../../../organisms/product'
import { FullPage } from '../../../../templates'
import { useProduct } from '../../../productContext'
import { ProposalSummaryPricing } from '../../pricing'
import { useProposal } from '../../proposalContext'
import { CoverDurationSection } from './components/CoverDurationSection'
import { OutstandingBalanceModal } from './components/OutstandingBalanceModal'
import { QuoteInformationSection } from './components/QuoteInformationSection'
import { StartCheckoutSection } from './components/StartCheckoutSection'
import { VerificationFailedModal } from './components/VerificationFailedModal'

const tKey = TKey('pages.proposal-summary')

export const ProposalSummaryPage: FunctionComponent = () => {
  const { t } = useWriskTranslation()

  const modal = useModal()

  const { product } = useProduct()
  const { proposal } = useProposal()

  const quote = getCurrentQuote(proposal)

  const onEditDisclosures = useEditProposalCallback(proposal)

  const onCheckoutBind = useCheckoutBind(
    (checks) => {
      modal.show({
        content: <VerificationFailedModal checks={checks} />,
      })
    },
    () => modal.show({ content: <OutstandingBalanceModal /> }),
  )

  return (
    <FullPage pageId='proposal-summary-page' header={t(tKey('header'))}>
      <ProposalSummaryPricing
        product={product}
        proposal={proposal}
        quote={quote}
        mb={smBumps}
      />
      <CoverDurationSection proposal={proposal} mb={xxlBumps} />
      <QuoteInformationSection onEditDetails={onEditDisclosures} quote={quote} />
      <YourCoverSection quote={quote} />
      <ProposalDocumentsSection quote={quote} mb={xlBumps} />
      <StartCheckoutSection
        product={product}
        onConfirm={onCheckoutBind.execute}
        loading={onCheckoutBind.loading}
      />
    </FullPage>
  )
}
