import {
  Box,
  Flex,
  FlexAnchor,
  Icon,
  lgBumps,
  LinkAnchor,
  mdBumps,
  ModalHeader,
  PageLoadingIndicator,
  SectionContent,
  SectionTitle,
  Typo,
  useModal,
  xxlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { generatePath, useNavigate } from 'react-router-dom'

import { Policyholder } from '../../domain'
import { toPolicyholderData } from '../../hooks/adjustments/policyholder'
import { TKey, useWriskTranslation } from '../../infrastructure/internationalisation'
import { Page, useUpButton } from '../../infrastructure/routing'
import { getAccountInputs, InputConfig, useConfig } from '../../state/configuration'
import { usePolicyholder } from '../authentication'
import { getFormatter } from '../formBuilder'
import useConditions from '../formBuilder/conditions'
import { FullPage } from '../templates'
import { AccountPath } from './AccountPath'

const tKey = TKey('pages.account-settings')

interface AccountSettingContentProps {
  policyholder: Policyholder
  inputs: InputConfig[]
  onClick: (input: InputConfig) => void
}

const AccountSettingContent: FunctionComponent<AccountSettingContentProps> = ({
  inputs,
  policyholder,
  onClick,
}) => {
  const { t } = useWriskTranslation()

  const modal = useModal()
  const conditions = useConditions(inputs)

  const toData = toPolicyholderData(policyholder)

  const onReadOnlyClick = useCallback(() => {
    modal.show({
      content: (
        <Box>
          <ModalHeader header={t('defaults.inputs.readonly.header')} />
          <Typo>{t('defaults.inputs.readonly.content')}</Typo>
        </Box>
      ),
    })
  }, [modal, t])

  const onUpdateClick = useCallback(
    (input: InputConfig) => () => onClick(input),
    [onClick],
  )

  const accountSettings = inputs
    .filter((it) => it.conditional?.conditions.every(conditions) ?? true)
    .map((it, index, filteredSettings) => {
      const Formatter = getFormatter(it.type)

      const isReadOnly = it.readOnly?.conditions.every(conditions) ?? false

      const mb = filteredSettings.length - 1 === index ? undefined : xxlBumps

      return (
        <Box key={it.name} width={1} mb={mb}>
          <SectionTitle>{t(tKey('settings', it.name))}</SectionTitle>
          <SectionContent>
            {isReadOnly ? (
              <FlexAnchor
                backgroundColor='surfaceDisabled'
                p={mdBumps}
                onClick={onReadOnlyClick}
              >
                <Typo fontFamily='input' color='textDisabled'>
                  <Formatter input={it} value={toData(it)} t={t} />
                </Typo>
                <Icon icon='info' size='tiny' ml={lgBumps} />
              </FlexAnchor>
            ) : (
              <Flex
                variant='raised'
                width={1}
                flexDirection='column'
                justifyContent='flex-start'
              >
                <Flex p={mdBumps} width={1}>
                  <Typo>
                    <Formatter input={it} value={toData(it)} t={t} />
                  </Typo>
                  <LinkAnchor
                    typoSize='sm'
                    variant='standalone'
                    onClick={onUpdateClick(it)}
                  >
                    {t('defaults.actions.update')}
                  </LinkAnchor>
                </Flex>
              </Flex>
            )}
          </SectionContent>
        </Box>
      )
    })

  return (
    <Flex width={1} flexDirection='column' justifyContent='flex-start'>
      {accountSettings}
    </Flex>
  )
}

export const AccountSettings: FunctionComponent<{ parent: Page }> = ({ parent }) => {
  const { t } = useWriskTranslation()

  const navigate = useNavigate()
  const form = useForm()

  const { policyholder } = usePolicyholder()
  const upButton = useUpButton(parent)
  const accountInputs = useConfig(getAccountInputs)

  const onClick = useCallback(
    (input: InputConfig) => navigate(generatePath(AccountPath.ACCOUNT_SETTING, input)),
    [navigate],
  )

  return policyholder ? (
    <FullPage header={t(tKey('header'))} upButton={upButton} pageId='accountSettings'>
      <FormProvider {...form}>
        <AccountSettingContent
          policyholder={policyholder}
          inputs={accountInputs}
          onClick={onClick}
        />
      </FormProvider>
    </FullPage>
  ) : (
    <PageLoadingIndicator />
  )
}
