import { Flex, FlexProps, Icon, smBumps, Typo, xxsBumps } from '@wrisk/ui-components'
import React from 'react'

import { IncludedCover } from '../../../hooks/covers'
import {
  tCovers,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

interface IncludedCoverItemWithInfoProps extends Omit<FlexProps, 'ref'> {
  cover: IncludedCover
}

export const IncludedCoverItemWithInfo: React.FC<IncludedCoverItemWithInfoProps> = ({
  cover,
  ...props
}) => {
  const { t } = useWriskTranslation()

  return (
    <Flex
      justifyContent='flex-start'
      alignItems='flex-start'
      py={3}
      px={smBumps}
      {...props}
    >
      <Icon icon='tick' mr={smBumps} />
      <Flex flexDirection='column' alignItems='flex-start'>
        <Typo wordWrap='break-word'>{t(tCovers(cover.coverCode, 'name'))}</Typo>
        <Typo mt={xxsBumps} color='bodySecondary' wordWrap='break-word' typoSize='sm'>
          {t(tCovers(cover.coverCode, 'info'))}
        </Typo>
      </Flex>
    </Flex>
  )
}
