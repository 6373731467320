import { PageLoadingIndicator } from '@wrisk/ui-components'
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { AnonymousUser, BackBookTokenRequest } from '../../../clients/anonymous'
import { User } from '../../../clients/auth'
import { useAuth } from '../../../hooks/auth'
import airbrake from '../../../infrastructure/airbrake/airbrake'
import { useAnonymous, useApiClientFactory } from '../../../state'
import { useAuthenticate } from '../hooks'
import { PolicyholderManager } from '../policyholder'
import PrincipalProvider, { Principal } from '../PrincipalProvider'

export const isUser = (user: User | AnonymousUser | undefined): user is User =>
  Boolean(user && (user as User).username)

interface PrincipalManagerProps extends PropsWithChildren {
  user: User | AnonymousUser
}

const PrincipalManager: FunctionComponent<PrincipalManagerProps> = ({
  user,
  children,
}) => {
  const [userState, setUser] = useState<User | AnonymousUser>(user)

  const apiClientFactory = useApiClientFactory()
  const anonymous = useAnonymous()
  const auth = useAuth()

  const principal = useMemo<Principal>(
    () =>
      isUser(userState)
        ? {
            user: userState,
            isAuthenticated: true,
            isAdmin: false,
            apiClient: apiClientFactory(auth.getTokenSilently),
            refreshAnonymous: async () => {
              await anonymous.refresh()
            },
          }
        : {
            user: userState,
            isAuthenticated: false,
            isAdmin: false,
            apiClient: apiClientFactory(anonymous.getToken),
            backBook: async (request: BackBookTokenRequest) => {
              setUser(await anonymous.backBook(request))
            },
            refreshAnonymous: async () => {
              setUser(await anonymous.refresh())
            },
          },
    // eslint-disable-next-line
    [userState],
  )

  useEffect(() => {
    userState &&
      airbrake?.addFilter((notice) => {
        notice.context.user = {
          id: userState.userId,
        }
        return notice
      })

    window.dataLayer?.push({
      userId: userState?.userId,
    })
  }, [userState])

  return (
    <PrincipalProvider value={principal}>
      <PolicyholderManager>{children}</PolicyholderManager>
    </PrincipalProvider>
  )
}

export const UserManager: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { loading, result: user } = useAuthenticate(true)

  return loading ? (
    <PageLoadingIndicator />
  ) : user ? (
    <PrincipalManager user={user}>{children}</PrincipalManager>
  ) : null
}
