import {
  AssetDisclosureChange,
  Data,
  getCoreAsset,
  getCurrentQuote,
  PolicyChangeType,
  ProfileDisclosureChange,
  Proposal,
  ProposalAdjustmentRequest,
  QuoteDisclosureChange,
} from '../../../../domain'
import {
  AdjustmentInputConfig,
  AssetChangeConfig,
  ChangeConfig,
  ChangeType,
  ProfileChangeConfig,
  QuoteChangeConfig,
  StartDateChangeConfig,
} from '../../../../state/configuration'
import { hasUpdate, toUpdates } from '../../helpers'

type ProposalAdjustmentReducer<T extends ChangeConfig> = (
  proposalAdjustment: ProposalAdjustmentRequest,
  input: AdjustmentInputConfig<T>,
) => ProposalAdjustmentRequest

const toProposalAssetDisclosureReducer =
  (data: Data, proposal: Proposal): ProposalAdjustmentReducer<AssetChangeConfig> =>
  (proposalAdjustment, input) => {
    const quote = getCurrentQuote(proposal)
    const { assetCode } = getCoreAsset(quote)

    const changes: AssetDisclosureChange[] = toUpdates(input, data).map((update) => ({
      changeType: PolicyChangeType.ASSET_DISCLOSURE_CHANGE,
      assetCode,
      update,
    }))

    return {
      ...proposalAdjustment,
      changes: [...proposalAdjustment.changes, ...changes],
    }
  }

const toProposalQuoteDisclosureReducer =
  (data: Data): ProposalAdjustmentReducer<QuoteChangeConfig> =>
  (proposalAdjustment, question) => {
    const changes: QuoteDisclosureChange[] = toUpdates(question, data).map((update) => ({
      changeType: PolicyChangeType.QUOTE_DISCLOSURE_CHANGE,
      update,
    }))

    return {
      ...proposalAdjustment,
      changes: [...proposalAdjustment.changes, ...changes],
    }
  }

const toProfileDisclosureReducer =
  (data: Data): ProposalAdjustmentReducer<ProfileChangeConfig> =>
  (proposalAdjustment, question) => {
    const changes: ProfileDisclosureChange[] = toUpdates(question, data).map(
      (update) => ({
        changeType: PolicyChangeType.PROFILE_DISCLOSURE_CHANGE,
        update,
      }),
    )

    return {
      ...proposalAdjustment,
      changes: [...proposalAdjustment.changes, ...changes],
    }
  }

const toStartDateDisclosureReducer =
  (data: Data): ProposalAdjustmentReducer<StartDateChangeConfig> =>
  (proposalAdjustment, input) => ({
    ...proposalAdjustment,
    coverStart: data[input.name],
  })

export const toProposalAdjustment =
  (inputs: AdjustmentInputConfig[], checkIdentity: boolean, proposal: Proposal) =>
  (existingData: Data, data: Data): ProposalAdjustmentRequest => {
    const profileDisclosureReducer = toProfileDisclosureReducer(data)
    const assetDisclosureReducer = toProposalAssetDisclosureReducer(data, proposal)
    const disclosureReducer = toStartDateDisclosureReducer(data)
    const quoteDisclosureReducer = toProposalQuoteDisclosureReducer(data)

    return inputs.filter(hasUpdate(existingData, data)).reduce(
      (proposalAdjustment: ProposalAdjustmentRequest, input) => {
        switch (input.adjustment.changeType) {
          case ChangeType.PROFILE_DISCLOSURE:
            return profileDisclosureReducer(
              proposalAdjustment,
              input as AdjustmentInputConfig<ProfileChangeConfig>,
            )
          case ChangeType.ASSET_DISCLOSURE:
            return assetDisclosureReducer(
              proposalAdjustment,
              input as AdjustmentInputConfig<AssetChangeConfig>,
            )
          case ChangeType.QUOTE_DISCLOSURE:
            return quoteDisclosureReducer(
              proposalAdjustment,
              input as AdjustmentInputConfig<QuoteChangeConfig>,
            )
          case ChangeType.START_DATE_CHANGE:
            return disclosureReducer(
              proposalAdjustment,
              input as AdjustmentInputConfig<StartDateChangeConfig>,
            )
          default:
            throw new Error('Invalid adjustment type')
        }
      },
      {
        checkIdentity,
        changes: [],
      },
    )
  }
