import {
  Box,
  LinkAnchor,
  Notification,
  NotificationProps,
  Typo,
} from '@wrisk/ui-components'
import { TFunction } from 'i18next'
import React, { FunctionComponent } from 'react'

import { TKeyBuilder } from '../../../infrastructure/internationalisation'
import { RejectFiles } from './actions'

export interface RejectionNotificationProps extends NotificationProps {
  t: TFunction
  tKey: TKeyBuilder
  loading: boolean
  rejected: RejectFiles[]
  onDismiss: () => void
}

export const RejectionNotification: FunctionComponent<RejectionNotificationProps> = ({
  t,
  tKey,
  loading,
  rejected,
  onDismiss,
  ...props
}) => {
  return (
    <Notification type='standalone' variant='critical' {...props}>
      <Box mr={2}>
        <Typo mb={1} fontWeight='bold'>
          {t(tKey('rejections'))}
        </Typo>
        <Box as='ul' ml={4}>
          {rejected.map((it, index) => {
            const message = t([
              tKey('validation', it.error),
              tKey('validation', 'unknown'),
            ])

            return (
              <Typo key={index} as='li' style={{ listStyleType: 'disc' }}>
                {it.name} ({message})
              </Typo>
            )
          })}
        </Box>
      </Box>
      <LinkAnchor
        variant='standalone'
        disabled={loading}
        onClick={onDismiss}
        typoSize='sm'
      >
        {t(tKey('actions', 'dismiss'))}
      </LinkAnchor>
    </Notification>
  )
}
