import {
  Box,
  Flex,
  FlexAnchor,
  FlexAnchorProps,
  Icon,
  Notification,
  smBumps,
  Typo,
  useModal,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { CustomerStatementStatus, CustomerStatementSummary } from '../../../domain'
import { useErrorHandlingAsyncCallback } from '../../../hooks/errors'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { toDateTime } from '../../../util/date'
import { usePrincipal } from '../../authentication'
import { StatementModal } from './StatementModal'
import { StatementStatusIcon } from './StatementStatusIcon'

export interface StatementSummaryProps extends Omit<FlexAnchorProps, 'ref'> {
  accountId: string
  statementSummary: CustomerStatementSummary
}

const tKey = TKey('components.statement-summary')

export const StatementSummary: FunctionComponent<StatementSummaryProps> = ({
  accountId,
  statementSummary,
  ...props
}) => {
  const { t } = useWriskTranslation()
  const { apiClient } = usePrincipal()
  const { hide, show } = useModal()

  const {
    createdAt,
    status,
    statementBalance,
    amountOutstanding,
    paymentDueAt,
    statementNumber,
    final,
  } = statementSummary

  const { execute, loading } = useErrorHandlingAsyncCallback(async () => {
    const statement = await apiClient.getCustomerStatement(accountId, statementNumber)

    show({
      content: <StatementModal hide={hide} statement={statement} />,
    })
  })

  const isPendingStatusWithOutstandingBalance =
    status === CustomerStatementStatus.PENDING && amountOutstanding > 0

  // prettier-ignore
  const notificationContent =
    isPendingStatusWithOutstandingBalance && final
      ? t(tKey('pending-final-payment-notification'))
      : isPendingStatusWithOutstandingBalance && !final
        ? t(tKey('pending-payment-notification'), {
          paymentDueAt: t(tFormats('date.medium'), {
            value: toDateTime(paymentDueAt),
          }),
        })
        : final && amountOutstanding < 0
          ? t(tKey('pending-refund-notification'), {
            amount: t(tFormats('currency.long'), {
              amount: -amountOutstanding / 100,
            }),
          })
          : null

  return (
    <Box>
      <FlexAnchor
        variant='highlight'
        width={1}
        py={3}
        px={smBumps}
        onClick={execute}
        disabled={loading}
        {...props}
      >
        <Typo>{t(tFormats('date.medium'), { value: toDateTime(createdAt) })}</Typo>
        <Flex>
          <StatementStatusIcon status={status} mr={3} />
          <Typo mr={2}>
            {t(tFormats('currency.long'), {
              amount: statementBalance / 100,
            })}
          </Typo>
          {loading ? (
            <Icon icon='loadingDark' size='tiny' />
          ) : (
            <Icon icon='chevronRight' size='tiny' />
          )}
        </Flex>
      </FlexAnchor>
      {notificationContent && (
        <Notification layout='small' mt={2}>
          <Typo typoSize='sm'>{notificationContent}</Typo>
        </Notification>
      )}
    </Box>
  )
}
