import { LinkAnchor, LinkAnchorProps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { useWriskTranslation } from '../../../infrastructure/internationalisation'

export const WriskPrivacyPolicyLink: FunctionComponent<LinkAnchorProps> = ({
  children,
  ...props
}) => {
  const { t } = useWriskTranslation()

  return (
    <LinkAnchor
      fontFamily='body'
      fontWeight='normal'
      color='inherit'
      href={t<string>('links.privacy-policy')}
      target='_blank'
      {...props}
    >
      {children}
    </LinkAnchor>
  )
}
