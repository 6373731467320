import {
  Box,
  Flex,
  Heading,
  InvoiceItemCell,
  InvoiceTotalCell,
  mdBumps,
  smBumps,
  Status,
  Typo,
  xxsBumps,
} from '@wrisk/ui-components'
import { partition } from 'lodash'
import { Duration } from 'luxon'
import React, { FunctionComponent } from 'react'

import {
  CustomerStatementLineItem,
  CustomerStatementStatus,
  CustomerStatementSummary,
  isJournalLineItem,
  isMtaLineItem,
} from '../../../domain'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { toDateTime } from '../../../util/date'
import { IPT } from '../product'
import { StatementStatusIcon } from './StatementStatusIcon'

type InvoicesProps = {
  invoices: CustomerStatementLineItem[]
  statementSummary: CustomerStatementSummary
}

const tKey = TKey('components.statement-modal.invoices')

export const Invoices: FunctionComponent<InvoicesProps> = ({
  invoices,
  statementSummary,
}) => {
  const { t } = useWriskTranslation()

  const { statementBalance, subtotalBalance, createdAt, broughtForwardBalance, status } =
    statementSummary

  const statementType = status === CustomerStatementStatus.DRAFT ? 'draft' : 'default'

  const showSubtotalSection = subtotalBalance !== statementBalance
  const [journalInvoices, otherInvoices] = partition(invoices, isJournalLineItem)
  const [mtaInvoices, mainInvoices] = partition(otherInvoices, isMtaLineItem)

  const subheader =
    status === CustomerStatementStatus.DRAFT ? (
      <Typo typoSize='sm'>
        {t(tKey('draft.subheader'), {
          createdAt: t(tFormats('date.medium'), {
            value: toDateTime(createdAt),
          }),
        })}
      </Typo>
    ) : (
      <Typo typoSize='sm'>
        {t(tFormats('date.medium'), {
          value: toDateTime(createdAt),
        })}
      </Typo>
    )

  const broughtForwardBalanceItem =
    broughtForwardBalance !== 0 ? (
      <InvoiceItemCell
        header={t<string>(
          tKey('brought-forward-balance', broughtForwardBalance < 0 ? 'credit' : 'debit'),
        )}
        premium={t<string>(tFormats('currency.long'), {
          amount: broughtForwardBalance / 100,
        })}
        px={mdBumps}
      />
    ) : null

  return (
    <Box variant='raised'>
      <Box p={mdBumps} borderBottomWidth={1}>
        <Flex mb={2}>
          <Heading as='h2' variant='h2' typoSize='xl'>
            {t(tKey(statementType, 'header'))}
          </Heading>
          {status === CustomerStatementStatus.DRAFT ? (
            <Status typoSize='xs' variant='default'>
              {t(tKey('draft.status'))}
            </Status>
          ) : (
            <StatementStatusIcon status={status} iconSize='small' p={2} />
          )}
        </Flex>
        {subheader}
      </Box>
      {Boolean(mainInvoices.length) && (
        <Box borderBottomWidth={1} py={2}>
          {mainInvoices.map((item, index) => (
            <InvoiceItemCell
              key={index}
              header={item.description ?? 'Unknown'}
              subheader={
                item.servicePeriodStart
                  ? item.servicePeriodEnd
                    ? t<string>(tFormats('dateRange.medium'), {
                        start: toDateTime(item.servicePeriodStart),
                        end: toDateTime(item.servicePeriodEnd)?.minus(
                          Duration.fromMillis(1),
                        ),
                      })
                    : t<string>(tFormats('date.medium'), {
                        value: toDateTime(item.servicePeriodStart),
                      })
                  : undefined
              }
              premium={t<string>(tFormats('currency.long'), {
                amount: item.amount / 100,
              })}
              px={mdBumps}
              py={xxsBumps}
            />
          ))}
        </Box>
      )}

      {Boolean(mtaInvoices.length) && (
        <Box borderBottomWidth={1} py={2}>
          <Typo typoSize='xs' color='bodySecondary' px={mdBumps} py={xxsBumps}>
            {t(tKey('mtas.header'))}
          </Typo>
          {mtaInvoices.map((item, index) => (
            <InvoiceItemCell
              key={index}
              header={item.description ?? 'Unknown'}
              subheader={
                item.servicePeriodStart
                  ? item.servicePeriodEnd
                    ? t<string>(tFormats('dateRange.medium'), {
                        start: toDateTime(item.servicePeriodStart),
                        end: toDateTime(item.servicePeriodEnd)?.minus(
                          Duration.fromMillis(1),
                        ),
                      })
                    : t<string>(tFormats('date.medium'), {
                        value: toDateTime(item.servicePeriodStart),
                      })
                  : undefined
              }
              premium={t<string>(tFormats('currency.long'), {
                amount: item.amount / 100,
              })}
              px={mdBumps}
              py={xxsBumps}
            />
          ))}
        </Box>
      )}

      {showSubtotalSection && (
        <Box>
          <Flex px={mdBumps} py={smBumps} borderBottomWidth={1}>
            <Typo fontWeight='bold'>{t(tKey('subtotal'))}</Typo>
            <Typo fontWeight='bold' whiteSpace='nowrap'>
              {t(tFormats('currency.long'), {
                amount: subtotalBalance / 100,
              })}
            </Typo>
          </Flex>
          <Box borderBottomWidth={1} py={2}>
            {journalInvoices.map((invoice, index) => (
              <InvoiceItemCell
                key={index}
                header={t<string>(tKey('journal-descriptions', invoice.type))}
                premium={t<string>(tFormats('currency.long'), {
                  amount: invoice.amount / 100,
                })}
                px={mdBumps}
                py={xxsBumps}
              />
            ))}
            {broughtForwardBalanceItem}
          </Box>
        </Box>
      )}

      <InvoiceTotalCell
        header={t<string>(tKey('total'))}
        subheader={<IPT />}
        premium={t<string>(tFormats('currency.long'), {
          amount: statementBalance / 100,
        })}
        p={mdBumps}
      />
    </Box>
  )
}
