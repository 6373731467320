import {
  Box,
  Flex,
  LinkAnchor,
  mdBumps,
  PrimaryButton,
  Typo,
  xxlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useCancelPolicyAdjustment } from '../../../../hooks/actions/cancel'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { toDateTime } from '../../../../util/date'
import { FullPage } from '../../../templates'
import { PolicyPath } from '../PolicyPath'

export interface ConfirmationPageProps {
  parent: string
}

const tKey = TKey('pages.cancel-confirm')

export const CancelConfirmPage: FunctionComponent<ConfirmationPageProps> = ({
  parent,
}) => {
  const { t } = useWriskTranslation()

  const navigate = useNavigate()

  const {
    state: { reason, dateTime },
  } = useLocation()

  const onClick = useCancelPolicyAdjustment(reason, dateTime ?? undefined, () => {
    navigate('../../' + PolicyPath.CANCEL_CONFIRMATION)
  })

  return (
    <FullPage header={t(tKey('header'))}>
      <Flex width={1} variant='raised' px={mdBumps} py={5} mb={xxlBumps}>
        <Typo fontWeight='bold'>{t(tKey('effective.header'))}</Typo>
        <Typo>
          {dateTime
            ? t(tFormats('datetime.medium'), { value: toDateTime(dateTime) })
            : t(tKey('effective.immediate'))}
        </Typo>
      </Flex>
      <Flex width={1}>
        <PrimaryButton
          layout='fixed'
          variant='critical'
          onClick={onClick.execute}
          loading={onClick.loading}
        >
          {t(tKey('actions.continue'))}
        </PrimaryButton>
        <Box ml={mdBumps}>
          <LinkAnchor variant='standalone' typoSize='sm' href={parent}>
            {t(tKey('actions.cancel'))}
          </LinkAnchor>
        </Box>
      </Flex>
    </FullPage>
  )
}
