import {
  Box,
  Flex,
  Form,
  Heading,
  InputWrapper,
  LinkAnchor,
  mdBumps,
  PrimaryButton,
  SelectInput,
  SingleSelect,
  TextInput,
  xsBumps,
  xxlBumps,
} from '@wrisk/ui-components'
import { DateTime } from 'luxon'
import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'

import { dateTimeFormatter, PolicyStatus } from '../../../../domain'
import {
  tEntries,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { getProduct, useConfig } from '../../../../state/configuration'
import { getStartOfEachDay } from '../../../../util/date'
import { FullPage } from '../../../templates'
import { usePolicy } from '../policyContext'
import { CancelPath } from './CancelPath'

export interface CancelPageProps {
  parent: string
}

const tKey = TKey('pages.cancel')

const isFormDisabled = (
  cancellationReason: string | undefined,
  otherReason: string | undefined,
): boolean =>
  cancellationReason === undefined ||
  (cancellationReason === 'Other' && otherReason?.trim().length === 0)

export const CancelPage: FunctionComponent<CancelPageProps> = ({ parent }) => {
  const navigate = useNavigate()
  const {
    policy: { schemeCode, policyDetail },
  } = usePolicy()
  const { t } = useWriskTranslation()

  const product = useConfig(getProduct(schemeCode))

  const dateValues = useMemo(() => {
    const formatter = dateTimeFormatter('Immediately')

    const days =
      policyDetail.policyStatus === PolicyStatus.PENDING
        ? 1
        : Math.min(
            DateTime.fromISO(policyDetail.expiredAt).diff(DateTime.now(), 'days').days,
            30,
          )

    return getStartOfEachDay({ days }).map((it) => ({
      value: it.toISO()!,
      text: formatter(it),
    }))
  }, [policyDetail])

  const [cancellationReason, onReasonChange] = useState<string | undefined>(undefined)
  const [cancellationDate, onDateChange] = useState<string | undefined>(
    dateValues[0]?.value ?? undefined,
  )
  const [otherReason, setOtherReason] = useState<string>('')

  const onSubmit = useCallback(() => {
    const reason =
      cancellationReason === 'Other' ? `Other|${otherReason}` : cancellationReason

    cancellationDate &&
      reason &&
      navigate(CancelPath.CONFIRM, {
        state: {
          reason,
          dateTime:
            DateTime.fromISO(cancellationDate) < DateTime.local()
              ? null
              : cancellationDate,
        },
      })
  }, [cancellationReason, otherReason, cancellationDate, navigate])

  const onChangeOtherTextInput = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => setOtherReason(target.value),
    [],
  )

  const options = useMemo(
    () =>
      Object.entries(product.cancellationReasons).reduce(
        (acc, [id, key]) => ({
          ...acc,
          [id]: t(tEntries('cancellationReasons', key)),
        }),
        {},
      ),
    [product.cancellationReasons, t],
  )

  return (
    <FullPage
      header={t(tKey('header'))}
      subheader={t(tKey('subheader'))}
      helpLinkText={t(tKey('help-link-message'))}
      helpLinkUrl={t<string>('links.cancellationTerms')}
    >
      <Form formId='policy-cancellation-form' onSubmit={onSubmit}>
        <Box width={1} mb={xxlBumps}>
          <SingleSelect
            name='cancellation-reason'
            onChange={onReasonChange}
            value={cancellationReason}
            options={options}
          />
          {cancellationReason === 'Other' && (
            <Box width={[1, 1, 4 / 5]} mt={xsBumps}>
              <InputWrapper>
                <TextInput
                  type='text'
                  variant='ghost'
                  width={1}
                  placeholder='Please share more details'
                  value={otherReason}
                  onChange={onChangeOtherTextInput}
                />
              </InputWrapper>
            </Box>
          )}
        </Box>
        <Box width={1} mb={xxlBumps}>
          <Heading variant='h3' typoSize='md' pb={mdBumps}>
            {t(tKey('effective-header'))}
          </Heading>
          <Box width={1}>
            <SelectInput<string>
              value={cancellationDate}
              values={dateValues}
              onChange={onDateChange}
            />
          </Box>
        </Box>
        <Flex width={1}>
          <PrimaryButton
            type='submit'
            variant='critical'
            layout='fixed'
            disabled={isFormDisabled(cancellationReason, otherReason)}
            form='policy-cancellation-form'
          >
            {t(tKey('actions.continue'))}
          </PrimaryButton>
          <Box ml={mdBumps}>
            <LinkAnchor variant='standalone' typoSize='sm' href={parent}>
              {t(tKey('actions.cancel'))}
            </LinkAnchor>
          </Box>
        </Flex>
      </Form>
    </FullPage>
  )
}
