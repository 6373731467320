import { usePrincipal } from '../../application/authentication'
import { usePolicy } from '../../application/product/policy/policyContext'
import { useApiErrorHandlingAsyncCallback } from '../auth'

export const useCancelPolicyAdjustment = (
  cancellationReason: string,
  effectiveAt: string | undefined,
  onConfirm: () => void,
) => {
  const { apiClient } = usePrincipal()
  const { policy, setPolicy } = usePolicy()

  return useApiErrorHandlingAsyncCallback(async () => {
    const { adjustmentId, confirmed } = await apiClient.createPolicyAdjustment({
      adjustmentType: 'CAN',
      policyId: policy.policyId,
      reason: cancellationReason,
      changes: [],
      effectiveAt,
    })

    if (!confirmed) {
      await apiClient.confirmPolicyAdjustment(adjustmentId)
    }

    setPolicy(await apiClient.getPolicy(policy.policyId))
    onConfirm?.()
  })
}
