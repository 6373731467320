import { ProductConfig, ProductKind } from '../../../../../state/configuration'
import { PolicyPath } from '../../PolicyPath'
import { BillingTab } from './tabs/BillingTab'
import { ClaimsTab } from './tabs/ClaimsTab'
import { PolicyDetailsTab } from './tabs/PolicyDetailsTab'
import { PolicyDocumentsTab } from './tabs/PolicyDocumentsTab'
import { TabLoader } from './types'

export const policyDetails: TabLoader = () => ({
  id: 'policy-details',
  path: PolicyPath.DETAILS,
  TabPage: PolicyDetailsTab,
})

export const documents: TabLoader = () => ({
  id: 'documents',
  path: PolicyPath.DOCUMENTS,
  TabPage: PolicyDocumentsTab,
})

export const claims: TabLoader = () => ({
  id: 'claims',
  path: PolicyPath.CLAIMS,
  TabPage: ClaimsTab,
})

export const billing: TabLoader = (product: ProductConfig) => {
  switch (product.productKind) {
    case ProductKind.METERED:
    case ProductKind.SUBSCRIPTION:
    case ProductKind.ANNUAL:
      return {
        id: 'billing',
        path: PolicyPath.BILLING,
        TabPage: BillingTab,
      }
    case ProductKind.DRIVEAWAY:
      return undefined
  }
}
