import { Box, Flex, Heading, Typo, xsBumps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import { AccrualType, getCoverPremium, isCoverActive, Quote } from '../../../../../domain'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'

const tKey = TKey('pricing.metered')

interface MeteredPriceBreakdownFlexProps {
  quote: Quote
}

export const PolicyMeteredBreakdown: FunctionComponent<
  MeteredPriceBreakdownFlexProps
> = ({ quote }) => {
  const { t } = useWriskTranslation()

  const monthlyAmount = getCoverPremium(
    quote.priceBreakdown.filter(isCoverActive),
    AccrualType.MONTHLY,
  )
  const meteredAmount = getCoverPremium(
    quote.priceBreakdown.filter(isCoverActive),
    AccrualType.METERED,
  )

  return (
    <Flex width={1} p={5} flexDirection='column'>
      <Heading textAlign='center' mb={5}>
        {t(tKey('yourPremiums'))}
      </Heading>
      <Flex width={1} alignItems='stretch'>
        <Box
          width={1 / 2}
          py={4}
          px={3}
          backgroundColor='background'
          borderWidth={1}
          borderRadius={1}
        >
          <Typo whiteSpace='pre-line' textAlign='center' typoSize='xs'>
            {t(tKey('monthlyFixedCost'))}
          </Typo>
          <Typo textAlign='center' typoSize='md' fontWeight='bold' mt={xsBumps}>
            {t(tFormats('currency.long'), { amount: monthlyAmount })}
          </Typo>
        </Box>

        <Flex width='2rem' justifyContent='center'>
          <Typo textAlign='center' fontWeight='bold'>
            +
          </Typo>
        </Flex>

        <Box
          width={1 / 2}
          py={4}
          px={3}
          backgroundColor='background'
          borderWidth={1}
          borderRadius={1}
        >
          <Typo whiteSpace='pre-line' textAlign='center' typoSize='xs'>
            {t(tKey('perMileCost.header'))}
          </Typo>
          <Typo textAlign='center' typoSize='md' fontWeight='bold' mt={xsBumps}>
            {t(tFormats('currency.decimal'), { amount: meteredAmount * 100 })}
          </Typo>
        </Box>
      </Flex>

      <Box width={1} mt={5}>
        <Typo textAlign='center' typoSize='xs' color='bodySecondary'>
          <Trans
            t={t}
            i18nKey={tKey('ipt.statement')}
            components={{
              abbr: <abbr title={t<string>(tKey('ipt.abbr'))} />,
            }}
          />
        </Typo>
      </Box>
    </Flex>
  )
}
