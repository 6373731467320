import { Data } from './data'
import { Endorsement } from './endorsement'
import { InsuredAsset } from './insuredAsset'
import { InsuredProfile } from './insuredProfile'
import { PriceOption } from './priceOption'

export enum QuoteStatus {
  QUOTE = 'QUOTE',
  REFERRAL = 'REFERRAL',
}

export interface Quote {
  proposalCode: string
  data: Data
  priceBreakdown: PriceOption[]
  insuredAssets: InsuredAsset[]
  insuredProfiles: InsuredProfile[]
  endorsements: Endorsement[]
  promoCode?: string
  wordingVersion: string
  ratingStatus: QuoteStatus
  riskProfileId: string
  referralReasons: ReferralReason[]
}

export interface ReferralReason {
  message: string
}
