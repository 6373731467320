import { FlexProps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { getPolicyVersion, Policy } from '../../../../domain'
import { ProductConfig, ProductKind } from '../../../../state/configuration'
import {
  AnnualAdjustedPremium,
  DriveawayAdjustedPremium,
  SubscriptionAdjustedPremium,
} from '../../../organisms/pricing'
import { PolicyMeteredPricing } from './productKind/PolicyMeteredPricing'

export interface PricingSidebarFactoryProps extends FlexProps {
  policy: Policy
  product: ProductConfig
  expanded?: boolean
}

export const PolicySidebarPricing: FunctionComponent<PricingSidebarFactoryProps> = ({
  policy,
  product,
  ...props
}) => {
  const { quote } = getPolicyVersion(policy)

  switch (product.productKind) {
    case ProductKind.ANNUAL:
      return (
        <AnnualAdjustedPremium currentQuote={quote} product={product} p={2} {...props} />
      )
    case ProductKind.SUBSCRIPTION:
      return (
        <SubscriptionAdjustedPremium
          currentQuote={quote}
          product={product}
          p={2}
          {...props}
        />
      )
    case ProductKind.DRIVEAWAY:
      return (
        <DriveawayAdjustedPremium
          currentQuote={quote}
          expiredAt={policy.policyDetail.expiredAt}
          product={product}
          {...props}
        />
      )
    case ProductKind.METERED:
      return <PolicyMeteredPricing quote={quote} policyId={policy.policyId} {...props} />
  }
}
