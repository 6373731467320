import { Flex, FlexAnchor, Icon, mdBumps, smBumps, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  tDefaults,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { ProductDocument } from '../../../state/configuration'

export const ProductDocumentLink: FunctionComponent<ProductDocument> = ({ id, url }) => {
  const { t } = useWriskTranslation()

  return (
    <FlexAnchor
      key={id}
      id={`document-link-${id}`}
      width={1}
      variant='inset'
      px={mdBumps}
      py={3}
      href={url}
      target='_blank'
    >
      <Flex flexDirection='row' alignItems='center' mr={2}>
        <Icon icon='document' mr={smBumps} />
        <Typo>{t(tDefaults('documents', id))}</Typo>
      </Flex>
      <Icon icon='newTab' size='tiny' />
    </FlexAnchor>
  )
}
