import { Form, Illus, PrimaryButton } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { useDependencies } from '../../../../state'
import { FullPage } from '../../../templates'

const tKey = TKey('pages.payment-result-error')

export const PaymentResultErrorPage: FunctionComponent = () => {
  const { chatClient } = useDependencies()

  const { t } = useWriskTranslation()

  return (
    <FullPage
      pageId='checkout-page'
      header={t(tKey('header'))}
      subheader={t(tKey('subheader'))}
      upperHeader={<Illus alt='Failure' illus='bindError' />}
    >
      <Form formId='payment-failed' onSubmit={chatClient.show}>
        <PrimaryButton layout='fixed' type='submit' form='payment-result-error-form'>
          {t(tKey('actions.continue'))}
        </PrimaryButton>
      </Form>
    </FullPage>
  )
}
