import React, { FunctionComponent } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { isPolicyTerminated } from '../../../../domain'
import { Page } from '../../../../infrastructure/routing'
import { usePolicy } from '../policyContext'
import { CancelConfirmPage } from './CancelConfirmPage'
import { CancelPage } from './CancelPage'
import { CancelPath } from './CancelPath'

export const CancellationRouter: FunctionComponent<{ parent: Page }> = ({ parent }) => {
  const { policy } = usePolicy()

  return !isPolicyTerminated(policy) ? (
    <Routes>
      <Route key='cancel' index element={<CancelPage parent={parent.url} />} />
      <Route
        key='confirm'
        path={CancelPath.CONFIRM}
        element={<CancelConfirmPage parent={parent.url} />}
      />
      <Route path='*' element={<Navigate to={parent.url} />} />
    </Routes>
  ) : (
    <Navigate to={parent.url} />
  )
}
