import React, { FunctionComponent, ReactElement, ReactNode } from 'react'

import { Box, Flex, mdBumps, SecondaryButton, Typo } from '../../atoms'

export interface ConfirmationViewProps {
  onChange: () => void
  label: ReactNode | null
  details?: ReactElement
  lowerContent?: ReactElement
}

export const ConfirmationView: FunctionComponent<ConfirmationViewProps> = ({
  label,
  details,
  onChange,
  lowerContent,
}) => {
  const lowerSection = lowerContent && (
    <Box pt={mdBumps}>
      <Typo>{lowerContent}</Typo>
    </Box>
  )

  const detailsSection = details && (
    <Box width={1} marginTop={8}>
      <Typo>{details}</Typo>
    </Box>
  )

  return (
    <>
      <Flex width={1} variant='raised' p={mdBumps} flexWrap='wrap'>
        <Box pr={8}>
          <Typo fontFamily='input'>{label}</Typo>
        </Box>
        <SecondaryButton layout='small' data-testid='change-button' onClick={onChange}>
          Change
        </SecondaryButton>
        {detailsSection}
      </Flex>
      {lowerSection}
    </>
  )
}
