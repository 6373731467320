import {
  AnimatedPage,
  Header,
  HeaderSubtitle,
  HeaderTitle,
  MainSection,
  WideContainer,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  useProposalAdjustment,
  useProposalFormData,
} from '../../../../hooks/adjustments/proposal'
import { useContinueProposalCallback } from '../../../../hooks/proposal'
import {
  TExtends,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { FormErrors } from '../../../formBuilder'
import { Footer } from '../../../templates'
import { useProduct } from '../../productContext'
import { useProposal } from '../proposalContext'

const tKey = TKey('pages.proposal-expired')

export const ProposalExpiredPage: FunctionComponent = () => {
  const { t } = useWriskTranslation()
  const {
    product: { expiredProposal },
  } = useProduct()
  const { proposal } = useProposal()

  const onContinueProposalCallback = useContinueProposalCallback(proposal)

  const data = useProposalFormData(expiredProposal)

  const onSubmitAdjustment = useProposalAdjustment(
    expiredProposal,
    false,
    onContinueProposalCallback,
  )

  return (
    <AnimatedPage pageId='expired-proposal' footer={<Footer />}>
      <WideContainer>
        <MainSection>
          <Header>
            <HeaderTitle>{t(tKey('header'))}</HeaderTitle>
            <HeaderSubtitle>{t(tKey('subheader'))}</HeaderSubtitle>
          </Header>
          <FormErrors
            id='expired-proposal'
            key='expired-proposal'
            data={data}
            inputs={expiredProposal}
            onSubmit={onSubmitAdjustment.execute}
            loading={onSubmitAdjustment.loading}
            tKey={TExtends(tKey, 'form')}
            t={t}
          />
        </MainSection>
      </WideContainer>
    </AnimatedPage>
  )
}
