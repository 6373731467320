import {
  Box,
  LinkAnchor,
  LinkAnchorProps,
  smBumps,
  Typo,
  xlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import { getCurrentQuote } from '../../../../domain'
import { useEditProposalCallback } from '../../../../hooks/proposal'
import {
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { ReferralReasons } from '../../../organisms/quote'
import { FullPage } from '../../../templates'
import { useProposal } from '../proposalContext'
import { ReferralUpsellSection } from '../upsell/ReferralUpsellSection'

const tKey = TKey('pages.proposal-referral')

const CheckAnswersLink: FunctionComponent<LinkAnchorProps> = ({
  children,
  onClick,
  ...props
}) => (
  <LinkAnchor typoSize='sm' onClick={onClick} {...props}>
    {children}
  </LinkAnchor>
)

export const ProposalReferralPage: FunctionComponent = () => {
  const { t } = useWriskTranslation()

  const { proposal } = useProposal()
  const quote = getCurrentQuote(proposal)

  const onCheckAnswers = useEditProposalCallback(proposal)

  return (
    <FullPage
      pageId='referral'
      header={t(tKey('header'))}
      subheader={t(tKey('subheader'), { defaultValue: null })}
    >
      <ReferralReasons quote={quote} mb={smBumps} width={1} />
      <Box width={1} mb={xlBumps}>
        <Typo typoSize='sm'>
          <Trans
            t={t}
            i18nKey={tKey('actions.check')}
            components={{
              CheckAnswersLink: <CheckAnswersLink onClick={onCheckAnswers} />,
            }}
          />
        </Typo>
      </Box>
      <ReferralUpsellSection proposal={proposal} />
    </FullPage>
  )
}
