import { Data } from './data'
import { PolicyVersion } from './policy'
import { RiskProfileStatus } from './riskProfile'

export enum PolicyChangeType {
  ASSET_DISCLOSURE_CHANGE = 'ASSET_DISCLOSURE_CHANGE',
  PROFILE_DISCLOSURE_CHANGE = 'PROFILE_DISCLOSURE_CHANGE',
  ADDITIONAL_PROFILE_DISCLOSURE_CHANGE = 'ADDITIONAL_PROFILE_DISCLOSURE_CHANGE',
  QUOTE_DISCLOSURE_CHANGE = 'QUOTE_DISCLOSURE_CHANGE',
  ADD_PROFILE = 'ADD_PROFILE',
  ADDITIONAL_PROFILE_CREATE_CHANGE = 'ADDITIONAL_PROFILE_CREATE_CHANGE',
  REMOVE_PROFILE = 'REMOVE_PROFILE',
  ADD_COVER = 'ADD_COVER',
  REMOVE_COVER = 'REMOVE_COVER',
  SELECT_VOLUNTARY_EXCESS = 'SELECT_VOLUNTARY_EXCESS',
}

export interface JsonUpdate {
  path: string
  value?: Data
}

export interface PolicyChange {
  changeType: PolicyChangeType
}

export interface AddProfileChange extends PolicyChange {
  changeType: PolicyChangeType.ADD_PROFILE
  profileCode: string
}

export interface AdditionalProfileCreateChange extends PolicyChange {
  changeType: PolicyChangeType.ADDITIONAL_PROFILE_CREATE_CHANGE
  profileCode: string
}

export interface RemoveProfileChange extends PolicyChange {
  changeType: PolicyChangeType.REMOVE_PROFILE
  profileCode: string
}

export interface SelectVoluntaryExcessChange extends PolicyChange {
  changeType: PolicyChangeType.SELECT_VOLUNTARY_EXCESS
  excess: number
}

export interface AdditionalProfileDisclosureChange extends PolicyChange {
  changeType: PolicyChangeType.ADDITIONAL_PROFILE_DISCLOSURE_CHANGE
  profileCode: string
  update: JsonUpdate
}

export interface QuoteDisclosureChange extends PolicyChange {
  changeType: PolicyChangeType.QUOTE_DISCLOSURE_CHANGE
  update: JsonUpdate
}

export interface ProfileDisclosureChange extends PolicyChange {
  changeType: PolicyChangeType.PROFILE_DISCLOSURE_CHANGE
  update: JsonUpdate
}

export interface AssetDisclosureChange extends PolicyChange {
  changeType: PolicyChangeType.ASSET_DISCLOSURE_CHANGE
  update: JsonUpdate
}

export interface AddCoverDisclosureChange extends PolicyChange {
  changeType: PolicyChangeType.ADD_COVER
  assetCode: string
  coverCode: string
}

export interface RemoveCoverDisclosureChange extends PolicyChange {
  changeType: PolicyChangeType.REMOVE_COVER
  assetCode: string
  coverCode: string
}

export interface ProposalAdjustmentRequest {
  checkIdentity?: boolean
  coverStart?: string
  changes: Array<
    | AdditionalProfileDisclosureChange
    | AdditionalProfileCreateChange
    | AddProfileChange
    | AssetDisclosureChange
    | ProfileDisclosureChange
    | QuoteDisclosureChange
    | RemoveProfileChange
    | AddCoverDisclosureChange
    | RemoveCoverDisclosureChange
    | SelectVoluntaryExcessChange
  >
}

export type PolicyAdjustmentChange =
  | AddProfileChange
  | ProfileDisclosureChange
  | AdditionalProfileDisclosureChange
  | AdditionalProfileCreateChange
  | AssetDisclosureChange
  | QuoteDisclosureChange
  | RemoveProfileChange
  | AddCoverDisclosureChange
  | RemoveCoverDisclosureChange
  | SelectVoluntaryExcessChange

export interface PolicyAdjustmentRequest {
  policyId: string
  effectiveAt?: string | null
  changes: Array<PolicyAdjustmentChange>
  reason?: string
  adjustmentType: 'SDA' | 'MTA' | 'CAN'
}

export interface PolicyAdjustmentResponse {
  adjustmentId: string
  policyId: string
  confirmed: boolean
  verificationClear: boolean
  updatedPolicyVersions: PolicyVersion[]
  status: RiskProfileStatus
  prorataPremium: number

  changes: PolicyAdjustmentRequest['changes']
}
