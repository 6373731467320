export interface CustomerStatement {
  statementSummary: CustomerStatementSummary
  lineItems: CustomerStatementLineItem[]
}

export interface CustomerStatementSummary {
  statementNumber: number
  statementBalance: number
  subtotalBalance: number
  amountSettled: number
  amountOutstanding: number
  carriedForwardBalance: number
  broughtForwardBalance: number
  openedAt: string
  paymentDueAt: string | null
  createdAt: string
  status: CustomerStatementStatus
  final: boolean
}

export enum CustomerStatementStatus {
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  SETTLED = 'SETTLED',
  DRAFT = 'DRAFT',
}

export interface CustomerStatementLineItem {
  description?: string
  amount: number
  type: LineItemType
  subType: LineItemSubType
  status: LineItemStatus
  group: LineItemGroup
  effectiveAt: string
  servicePeriodStart?: string
  servicePeriodEnd?: string
}

export enum LineItemType {
  INVOICE = 'INVOICE',
  CREDIT_NOTE = 'CREDIT_NOTE',
  DEBIT_JOURNAL = 'DEBIT_JOURNAL',
  CREDIT_JOURNAL = 'CREDIT_JOURNAL',
  PAYMENT = 'PAYMENT',
  PAYMENT_REVERSAL = 'PAYMENT_REVERSAL',
  REFUND = 'REFUND',
  REFUND_REVERSAL = 'REFUND_REVERSAL',
}

export enum LineItemSubType {
  GOCARDLESS = 'GOCARDLESS',
  STRIPE = 'STRIPE',
  MTA = 'MTA',
}

export enum LineItemStatus {
  PENDING = 'PENDING',
  POSTED = 'POSTED',
  FAILED = 'FAILED',
}

export enum LineItemGroup {
  INVOICE = 'INVOICE',
  PAYMENT = 'PAYMENT',
}
