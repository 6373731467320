import { Box } from '@wrisk/ui-components'
import { partition } from 'lodash'
import React, { FunctionComponent } from 'react'

import { CustomerStatement, isInvoiceLineItem } from '../../../domain'
import { Invoices } from './Invoices'
import { Payments } from './Payments'

type StatementModalProps = {
  hide: () => void
  statement: CustomerStatement
}

export const StatementModal: FunctionComponent<StatementModalProps> = ({
  statement: { statementSummary, lineItems },
}) => {
  const [invoices, payments] = partition(lineItems, isInvoiceLineItem)

  return (
    <Box>
      <Invoices invoices={invoices} statementSummary={statementSummary} />
      <Payments payments={payments} statementSummary={statementSummary} />
    </Box>
  )
}
