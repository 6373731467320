import {
  Flex,
  FlexAnchor,
  Icon,
  mdBumps,
  smBumps,
  Status,
  Typo,
} from '@wrisk/ui-components'
import { isNil } from 'lodash'
import React, { FunctionComponent, useCallback, useState } from 'react'

import {
  AdditionalProfileDisclosureChange,
  AddProfileChange,
  getCurrentPolicyVersion,
  getInsuredProfile,
  getName,
  getPolicyVersion,
  PolicyAdjustmentResponse,
  PolicyChangeType,
  RemoveProfileChange,
} from '../../../../../../../domain'
import { toAdditionalProfilePolicyData } from '../../../../../../../hooks/adjustments/policy/additionalProfile/data'
import {
  tDefaults,
  TKey,
  useWriskTranslation,
} from '../../../../../../../infrastructure/internationalisation'
import { AdditionalProfileManagementConfig } from '../../../../../../../state/configuration'
import { groupBy } from '../../../../../../../util/array'
import { getFormatter } from '../../../../../../formBuilder'
import { usePolicy } from '../../../../policyContext'
import { EffectiveAt } from './EffectiveAt'

export interface ProfileChangesFlexProps {
  result: PolicyAdjustmentResponse
  config: AdditionalProfileManagementConfig
}

interface ProfileChangeFlexProps {
  result: PolicyAdjustmentResponse
  config: AdditionalProfileManagementConfig
  profileCode: string
  changes: Array<
    AddProfileChange | RemoveProfileChange | AdditionalProfileDisclosureChange
  >
}

const tKey = TKey('components.profile-changes')

const ProfileChangeFlex: FunctionComponent<ProfileChangeFlexProps> = ({
  result,
  config,
  profileCode,
  changes,
}) => {
  const { t } = useWriskTranslation()

  const { policy } = usePolicy()

  const [expanded, setExpanded] = useState(false)
  const onExpand = useCallback(() => setExpanded((e) => !e), [setExpanded])

  const { quote: originalQuote } = getPolicyVersion(policy)
  const { quote: updatedQuote } = getCurrentPolicyVersion(result.updatedPolicyVersions)

  const profile =
    getInsuredProfile(updatedQuote, profileCode) ??
    getInsuredProfile(originalQuote, profileCode)

  if (!profile) return null

  const toData = toAdditionalProfilePolicyData(profile)

  const status = changes.some((it) => it.changeType === PolicyChangeType.ADD_PROFILE) ? (
    <Status variant='success' typoSize='xs'>
      {t(tKey('status.added'))}
    </Status>
  ) : changes.some((it) => it.changeType === PolicyChangeType.REMOVE_PROFILE) ? (
    <Status variant='critical' typoSize='xs'>
      {t(tKey('status.removed'))}
    </Status>
  ) : (
    <Status typoSize='xs'>{t(tKey('status.changed'))}</Status>
  )

  const expandedComponents = config.inputs
    .filter(({ adjustment }) => !isNil(toData(adjustment)))
    .map((input) => {
      const Formatter = getFormatter(input.type)

      return (
        <Flex
          key={input.name}
          px={mdBumps}
          py={2}
          flexDirection='column'
          alignItems='flex-start'
        >
          <Typo typoSize='xs' color='bodySecondary' mb={1}>
            {t(tDefaults('product.headers', input.name))}
          </Typo>
          <Typo as='pre'>
            <Formatter input={input} value={toData(input.adjustment)} t={t} />
          </Typo>
        </Flex>
      )
    })

  return (
    <>
      <FlexAnchor mx={2} p={smBumps} variant='highlight' onClick={onExpand}>
        <Typo fontWeight='bold' mr={2}>
          {getName(profile)}
        </Typo>
        <Flex>
          {status}
          <Icon
            icon='chevronDown'
            size='tiny'
            rotate={expanded ? 180 : 0}
            ml={2}
            transition='default'
          />
        </Flex>
      </FlexAnchor>
      {expanded && expandedComponents}
    </>
  )
}

export const ProfileChangesFlex: FunctionComponent<ProfileChangesFlexProps> = ({
  result,
  config,
}) => {
  const relevantChanges = result.changes.reduce((acc, change) => {
    switch (change.changeType) {
      case PolicyChangeType.ADD_PROFILE:
      case PolicyChangeType.REMOVE_PROFILE:
      case PolicyChangeType.ADDITIONAL_PROFILE_DISCLOSURE_CHANGE:
        return [...acc, change]
      default:
        return acc
    }
  }, [])

  const groupedChanges = groupBy(relevantChanges, (change) => change.profileCode)
  const policyVersion = getCurrentPolicyVersion(result.updatedPolicyVersions)

  const elements = Array.from(groupedChanges).map(([profileCode, changes]) => (
    <ProfileChangeFlex
      key={profileCode}
      profileCode={profileCode}
      config={config}
      result={result}
      changes={changes}
    />
  ))

  return (
    <Flex alignItems='stretch' flexDirection='column' pt={2} variant='raised' width={1}>
      {elements}
      <EffectiveAt mt={2} effectiveAt={policyVersion.startedAt} />
    </Flex>
  )
}
