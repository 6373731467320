import { Flex, Heading, LicencePlate, mdBumps, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { getCoreAsset, Quote, toVehicleDescription } from '../../../domain'
import {
  tDefaults,
  tProduct,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

export interface QuoteHeaderProps {
  quote: Quote
}

export const QuoteHeader: FunctionComponent<QuoteHeaderProps> = ({ quote }) => {
  const { t } = useWriskTranslation()

  const coreAsset = getCoreAsset(quote)

  const description = coreAsset.data.specification
    ? toVehicleDescription(coreAsset.data.specification) // TODO (MK): Move to translations?
    : t(tDefaults('product', 'assetType'))

  return (
    <Flex
      p={mdBumps}
      flexDirection='column'
      justifyContent='flex-start'
      alignItems='flex-start'
    >
      <Typo mb={2}>{t(tProduct('name'))}</Typo>
      <Heading typoSize='lg' mb={[2, 3]}>
        {description}
      </Heading>
      <LicencePlate variant='subtle' licencePlate={coreAsset.data.vrn} />
    </Flex>
  )
}
