import { Flex, FlexProps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { Quote } from '../../../../../domain'
import { MeteredActualUsage } from '../../../../organisms/pricing'
import { PolicyMeteredBreakdown } from './PolicyMeteredBreakdown'

export interface MeteredPricingFlexProps extends FlexProps {
  policyId: string
  quote: Quote
}

export const PolicyMeteredPricing: FunctionComponent<MeteredPricingFlexProps> = ({
  policyId,
  quote,
  ...props
}) => {
  return (
    <Flex flexDirection='column' justifyContent='flex-start' width={1} {...props}>
      <MeteredActualUsage
        policyId={policyId}
        variant='prominent'
        borderTopLeftRadius={2}
        borderTopRightRadius={2}
      />
      <PolicyMeteredBreakdown quote={quote} />
    </Flex>
  )
}
