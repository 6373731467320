import {
  Flex,
  Header,
  HeaderTitle,
  HeaderUpperContent,
  Icon,
  LinkAnchor,
  pageEntranceSpringValues,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { animated, useSpring } from 'react-spring'

import {
  getCurrentQuote,
  getInsuredProfileStrict,
  Profile,
} from '../../../../../../domain'
import {
  useAdditionalProfileFormData,
  useAdditionalProfileProposalAdjustment,
} from '../../../../../../hooks/adjustments/proposal'
import {
  TExtends,
  TKey,
  useWriskTranslation,
} from '../../../../../../infrastructure/internationalisation'
import { useRequiredParams } from '../../../../../../infrastructure/routing'
import { AdditionalProfileDisclosureConfig } from '../../../../../../state/configuration'
import { FormErrors } from '../../../../../formBuilder'
import { useProposal } from '../../../proposalContext'

const tKey = TKey('pages.additional-profile')

export interface AdditionalProfileDisclosureProps {
  section: AdditionalProfileDisclosureConfig
}

const Content: FunctionComponent<
  AdditionalProfileDisclosureProps & { profile: Profile }
> = ({ section, profile }) => {
  const spring = useSpring(pageEntranceSpringValues)
  const navigate = useNavigate()

  const { t } = useWriskTranslation()

  const data = useAdditionalProfileFormData(section.inputs, profile)
  const onSubmitAdjustment = useAdditionalProfileProposalAdjustment(
    section.inputs,
    profile,
    () => navigate('..'),
  )

  return (
    <animated.div style={spring}>
      <Header>
        <HeaderUpperContent>
          <Flex justifyContent='flex-start' display='inline-flex' as={Link} to='..'>
            <Icon size='small' pl={2} icon='chevronRight' rotate={180} />
            <LinkAnchor fontFamily='buttons' variant='standalone' typoSize='sm'>
              {t([tKey('actions.back'), 'defaults.actions.back'])}
            </LinkAnchor>
          </Flex>
        </HeaderUpperContent>
        <HeaderTitle>{t(tKey('header'))}</HeaderTitle>
      </Header>
      <FormErrors
        id={section.id}
        key={section.id}
        footerText={section.footerText}
        data={data}
        inputs={section.inputs}
        onSubmit={onSubmitAdjustment.execute}
        loading={onSubmitAdjustment.loading}
        tKey={TExtends(tKey, 'form')}
        t={t}
      />
    </animated.div>
  )
}

export const AdditionalProfileDisclosure: FunctionComponent<
  AdditionalProfileDisclosureProps
> = ({ section }) => {
  const { proposal } = useProposal()

  const { profileCode } = useRequiredParams()

  const quote = getCurrentQuote(proposal)

  const profile = getInsuredProfileStrict(quote, profileCode)

  return <Content section={section} profile={profile} />
}

export const AdditionalProfileCreateDisclosure: FunctionComponent<
  AdditionalProfileDisclosureProps
> = ({ section }) => {
  return <Content section={section} profile={{ profileCode: 'p1', data: {} }} />
}
