import {
  Box,
  BoxProps,
  Flex,
  LinkAnchor,
  Progress,
  smBumps,
  Typo,
} from '@wrisk/ui-components'
import { TFunction } from 'i18next'
import React, { FunctionComponent } from 'react'

import { TKeyBuilder } from '../../../infrastructure/internationalisation'

export interface AcceptedListProps extends BoxProps {
  t: TFunction
  tKey: TKeyBuilder
  accepted: File[]
  progress: Record<string, number>
  loading: boolean
  onRemove: (file: File) => void
}

export const AcceptedList: FunctionComponent<AcceptedListProps> = ({
  t,
  tKey,
  accepted,
  progress,
  loading,
  onRemove,
  ...props
}) => {
  return (
    <Box variant='raised' p={2} {...props}>
      {accepted.map((file, index) => {
        const fileProgress = progress[file.name] ?? 0
        return (
          <Flex p={smBumps} key={index} py={smBumps}>
            <Typo mr={2}>{file.name}</Typo>
            {fileProgress === 1 ? (
              <LinkAnchor
                variant='standalone'
                disabled={loading}
                onClick={() => onRemove(file)}
                typoSize='sm'
              >
                {t(tKey('actions', 'remove'))}
              </LinkAnchor>
            ) : (
              <Progress
                percentage={fileProgress}
                width={['48px', '48px', '48px', '64px']}
              />
            )}
          </Flex>
        )
      })}
    </Box>
  )
}
