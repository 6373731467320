import {
  Box,
  FlexAnchor,
  FlexAnchorProps,
  HelpLinkIFrame,
  Icon,
  ModalHeader,
  smBumps,
  Typo,
  useModal,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'

import { PriceOption, RiskProfileStatus } from '../../../domain'
import {
  tCovers,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

interface OptionalCoverItemProps extends Omit<FlexAnchorProps, 'ref'> {
  cover: PriceOption
  contentBaseUrl: string
}

export const OptionalCoverItem: FunctionComponent<OptionalCoverItemProps> = ({
  cover,
  contentBaseUrl,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const modal = useModal()

  const inReferral = cover.ratingStatus === RiskProfileStatus.REFERRAL

  const iconName = inReferral ? 'cross' : cover.active ? 'tick' : 'plus'
  const color = inReferral ? 'inactive' : undefined

  const onClick = useCallback(() => {
    cover.ratingStatus === RiskProfileStatus.REFERRAL
      ? modal.show({
          content: (
            <Box>
              <ModalHeader header={t(tCovers(cover.coverCode, 'name'))} />
              <Typo>{t(tCovers(cover.coverCode, 'unavailable'))}</Typo>
            </Box>
          ),
        })
      : modal.show({
          content: <HelpLinkIFrame src={`${contentBaseUrl}/${cover.coverCode}.html`} />,
          variant: 'iframe',
        })
  }, [contentBaseUrl, cover, modal, t])

  return (
    <FlexAnchor
      variant='highlight'
      justifyContent='flex-start'
      py={3}
      px={smBumps}
      onClick={onClick}
      {...props}
    >
      <Icon icon={iconName} color={color} mr={smBumps} />
      <Typo color={color}>{t(tCovers(cover.coverCode, 'name'))}</Typo>
    </FlexAnchor>
  )
}
