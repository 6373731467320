import css from '@styled-system/css'
import styled from 'styled-components'
import {
  compose,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  variant,
} from 'styled-system'

import { collapse, CollapseProps } from './collapse'

export type WideContainerProperty = 'default' | 'sidebar'

export interface WideContainerProps
  extends FlexboxProps,
    LayoutProps,
    SpaceProps,
    CollapseProps {
  variant?: WideContainerProperty
}

const widths = ['87.5%', '420px', '720px', '944px', '992px']

export const WideContainer = styled.div<WideContainerProps>(
  css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    position: 'relative',
    width: widths,
    minWidth: widths,
    mx: 'auto',
  }),
  variant({
    scale: 'WideContainer',
    variants: {
      default: {},
      sidebar: {
        flexDirection: ['column', 'column', 'row-reverse'],
        justifyContent: ['flex-start', 'flex-start', 'center'],
        alignItems: 'flex-start',
      },
    },
  }),
  compose(flexbox, space, layout, collapse),
)

WideContainer.defaultProps = {
  variant: 'default',
}
