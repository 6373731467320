import {
  Box,
  Flex,
  FlexAnchor,
  Icon,
  smBumps,
  Status,
  StatusProperty,
  Typo,
  xsBumps,
  xxsBumps,
} from '@wrisk/ui-components'
import React, { useCallback, useState } from 'react'

import {
  AccrualType,
  AddCoverDisclosureChange,
  getCoverPremium,
  getPolicyVersion,
  PolicyChangeType,
  RemoveCoverDisclosureChange,
} from '../../../../../../../../domain'
import { useOptionalCovers } from '../../../../../../../../hooks/covers'
import {
  tCovers,
  tDefaults,
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../../../../../infrastructure/internationalisation'
import { useProduct } from '../../../../../../productContext'
import { usePolicy } from '../../../../../policyContext'

interface Props {
  change: AddCoverDisclosureChange | RemoveCoverDisclosureChange
}

const tKey = TKey('components.add-on-changes')

interface CoverDetail {
  title: string
  value: string
}

const AddOnChangesItem: React.FC<Props> = ({ change }) => {
  const [open, setOpen] = useState(false)

  const { t } = useWriskTranslation()

  const { policy } = usePolicy()
  const { product } = useProduct()

  const { quote } = getPolicyVersion(policy)

  const [cover] = useOptionalCovers(quote, product).filter(
    (it) => it.coverCode === change.coverCode,
  )

  const premiumAmount = getCoverPremium([cover], AccrualType.MONTHLY)
  const coverDetail = t(tCovers(cover.coverCode, 'detail'), {
    returnObjects: true,
    defaultValue: [],
  }) as CoverDetail[]

  const onItemClicks = useCallback(() => setOpen((old) => !old), [])

  const { variant, status }: { variant: StatusProperty; status: string } =
    change.changeType === PolicyChangeType.ADD_COVER
      ? {
          variant: 'success',
          status: t(tKey('status', 'added')),
        }
      : {
          variant: 'critical',
          status: t(tKey('status', 'removed')),
        }

  return (
    <>
      <FlexAnchor mx={2} p={smBumps} variant='highlight' onClick={onItemClicks}>
        <Typo fontWeight='bold' mr={2}>
          {t(tDefaults('product.headers', change.coverCode))}
        </Typo>
        <Flex>
          <Status variant={variant} typoSize='xs'>
            {status}
          </Status>
          <Icon
            ml={xsBumps}
            icon='chevronDown'
            rotate={open ? 180 : 0}
            transition='default'
            size='tiny'
          />
        </Flex>
      </FlexAnchor>
      {open && (
        <Box mx={2} px={smBumps}>
          {coverDetail.map((detail, i) => (
            <Flex key={i} my={xxsBumps}>
              <Typo>{detail.title}</Typo>
              <Typo>{detail.value}</Typo>
            </Flex>
          ))}
          <Flex my={xxsBumps}>
            <Typo>{t(tKey('premium'))}</Typo>
            <Typo>{t(tFormats('currency.long'), { amount: premiumAmount })}</Typo>
          </Flex>
        </Box>
      )}
    </>
  )
}

export default AddOnChangesItem
