import React, { FunctionComponent, PropsWithChildren } from 'react'

import { useApiErrorHandlingAsync } from '../../../hooks/auth'
import { usePrincipal } from '../PrincipalProvider'
import { PolicyholderProvider } from './policyholderContext'

export const PolicyholderManager: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { apiClient } = usePrincipal()

  const policyholderAsync = useApiErrorHandlingAsync(apiClient.getPolicyholder, [
    apiClient,
  ])

  return (
    <PolicyholderProvider policyholder={policyholderAsync.result}>
      {children}
    </PolicyholderProvider>
  )
}
