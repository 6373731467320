import { Box, ModalHeader } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { Proposal } from '../../../../domain'
import { useProposalAdjustment } from '../../../../hooks/adjustments/proposal'
import {
  TExtends,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { AdjustmentInputConfig } from '../../../../state/configuration'
import { FormData, FormDisabled } from '../../../formBuilder'

export interface ProposalConfirmationModalProps {
  inputs: AdjustmentInputConfig[]
  data: FormData
  onComplete: (proposal: Proposal) => void
}

const tKey = TKey('components.proposal-confirmation-modal')

export const ProposalConfirmationModal: FunctionComponent<
  ProposalConfirmationModalProps
> = ({ inputs, data, onComplete }) => {
  const { t } = useWriskTranslation()

  const onSubmitAdjustment = useProposalAdjustment(inputs, true, onComplete)

  return (
    <Box>
      <ModalHeader header={t(tKey('header'))} subheader={t(tKey('subheader'))} />
      <FormDisabled
        id='proposalConfirmation'
        data={data}
        inputs={inputs}
        onSubmit={onSubmitAdjustment.execute}
        loading={onSubmitAdjustment.loading}
        tKey={TExtends(tKey, 'inputs')}
        options={{ shouldUnregister: true }}
        t={t}
      />
    </Box>
  )
}
