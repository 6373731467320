import { Illus, LinkAnchor, xlBumps } from '@wrisk/ui-components'
import { DateTime } from 'luxon'
import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { useAuth } from '../../../../hooks/auth'
import { useErrorHandlingAsyncCallback } from '../../../../hooks/errors'
import { useNavigateCallback } from '../../../../hooks/navigation'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { getFeatureToggles, useConfig } from '../../../../state/configuration'
import { toDateTime } from '../../../../util/date'
import { AppPath } from '../../../AppPath'
import { usePrincipal } from '../../../authentication'
import { Feedback } from '../../../organisms/feedback/Feedback'
import { FullPage } from '../../../templates'
import { useProposal } from '../proposalContext'
import { CheckoutUpsellSection } from '../upsell/CheckoutUpsellSection'

const tKey = TKey('pages.checkout-success')

export const CheckoutSuccessPage: FunctionComponent = () => {
  const { t } = useWriskTranslation()

  const auth = useAuth()
  const { renderCheckoutAction } = useConfig(getFeatureToggles)
  const { proposal } = useProposal()
  const { isAuthenticated } = usePrincipal()

  const onContinue = useNavigateCallback(AppPath.HOME)
  const onSignInCallback = useErrorHandlingAsyncCallback(() =>
    auth.signIn(generatePath(AppPath.HOME)),
  )

  const startAt = proposal.startAt

  const subheader =
    startAt && DateTime.fromISO(startAt) > DateTime.local() ? (
      <Trans
        t={t}
        i18nKey={tKey('deferred')}
        values={{
          startAt: t(tFormats('date.long'), { value: toDateTime(startAt) }),
        }}
      />
    ) : (
      t(tKey('subheader'))
    )

  const actionToRender =
    renderCheckoutAction &&
    (isAuthenticated ? (
      <LinkAnchor onClick={onContinue} variant='standalone'>
        {t(tKey('actions.continue'))}
      </LinkAnchor>
    ) : (
      <LinkAnchor onClick={onSignInCallback.execute} variant='standalone'>
        {t(tKey('actions.sign-in'))}
      </LinkAnchor>
    ))

  return (
    <FullPage
      pageId='checkout-page'
      header={t(tKey('header'))}
      subheader={subheader}
      upperHeader={<Illus alt='Success' data-testid='bind-success' illus='bindSuccess' />}
    >
      {actionToRender}

      <CheckoutUpsellSection mt={xlBumps} proposal={proposal} />

      <Feedback type='bind' tags={{ policyId: proposal.policyId ?? '' }} />
    </FullPage>
  )
}
