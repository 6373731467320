import React, { FunctionComponent } from 'react'

import { Box } from '../atoms'
import { useNotifyingState } from '../extensions'
import { DateInput, fromInputsToIsoDate, fromIsoDateToInputs } from './DateInput'
import { InputMessage } from './InputMessage'

export interface DateFeedbackInputProps {
  error: string | undefined
  onChange: (date: string | undefined) => void
  initialValue?: string
}

export const DateFeedbackInput: FunctionComponent<DateFeedbackInputProps> = (props) => {
  const { initialValue, onChange, error } = props

  const initialDateInputs = fromIsoDateToInputs(initialValue)

  const [dateInputs, setDate] = useNotifyingState(initialDateInputs, [
    (d) => onChange(fromInputsToIsoDate(d)),
  ])

  return (
    <Box>
      <DateInput onChange={setDate} dateInputs={dateInputs} />
      {error && <InputMessage>{error}</InputMessage>}
    </Box>
  )
}
