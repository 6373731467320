import {
  Box,
  Flex,
  Form,
  mdBumps,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  SingleSelect,
  Typo,
  xsBumps,
} from '@wrisk/ui-components'
import { YearMonthDayInput } from '@wrisk/ui-components/src/inputs/YearMonthDayInput'
import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'

import { getExpiryDate, getInceptedDate } from '../../../../../domain'
import {
  tEntries,
  TExtends,
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'
import { toDateTime } from '../../../../../util/date'
import { AdjustmentModalProps } from './types'
import {
  isBeforeExpiry,
  isOnOrAfterInception,
  isValidDate,
  isWithin30Days,
} from './validation'

const tKey = TKey('components.active-adjustment-modal')

interface AdjustmentModalFormData {
  select: AdjustmentOption
  date: string | null
}

enum AdjustmentOption {
  Immediately = 'immediately',
  BackdateToInception = 'backdate-to-inception',
  OtherDate = 'other-date',
}

const tOptions = TExtends(tEntries, 'mtaEffectiveAt')

export const ActiveAdjustmentModal: FunctionComponent<AdjustmentModalProps> = ({
  updatedData,
  policy,
  onCancel,
  onSubmitAdjustment,
}) => {
  const { t } = useWriskTranslation()

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<AdjustmentModalFormData>({
    defaultValues: { select: AdjustmentOption.Immediately },
  })

  const options = useMemo(
    () => ({
      [AdjustmentOption.Immediately]: t(tOptions(AdjustmentOption.Immediately)),
      [AdjustmentOption.BackdateToInception]: t(
        tOptions(AdjustmentOption.BackdateToInception),
      ),
      [AdjustmentOption.OtherDate]: t(tOptions(AdjustmentOption.OtherDate)),
    }),
    [t],
  )

  const earliestYear = getInceptedDate(policy).year
  const latestYear = getExpiryDate(policy).year

  const onSubmit = useCallback(
    (data: AdjustmentModalFormData) => {
      const effectiveAt = (() => {
        switch (data.select) {
          case AdjustmentOption.OtherDate:
            return data.date
          case AdjustmentOption.BackdateToInception:
            return policy.policyDetail.inceptedAt
          case AdjustmentOption.Immediately:
            return undefined
        }
      })()

      void onSubmitAdjustment.execute(
        updatedData,
        toDateTime(effectiveAt)?.toISO() ?? undefined,
      )
    },
    [onSubmitAdjustment, policy, updatedData],
  )

  return (
    <Box>
      <ModalHeader header={t(tKey('header'))} />
      <Form formId='active-adjustment-form' onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='select'
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <SingleSelect
              name={name}
              value={value}
              onChange={onChange}
              options={options}
            />
          )}
        />
        {watch('select') === 'other-date' && (
          <Box mt={mdBumps}>
            <Controller
              name='date'
              control={control}
              rules={{
                validate: {
                  isValidDate,
                  isOnOrAfterInception: isOnOrAfterInception(
                    policy.policyDetail.inceptedAt,
                  ),
                  isWithin30Days,
                  isBeforeExpiry: isBeforeExpiry(policy.policyDetail.expiredAt),
                },
              }}
              render={({ field }) => (
                <YearMonthDayInput
                  {...field}
                  earliestYear={earliestYear}
                  latestYear={latestYear}
                />
              )}
            />
            {errors.date && (
              <Typo mt={xsBumps} color='textCritical'>
                <Trans
                  t={t}
                  i18nKey={tKey('errors', errors.date.type)}
                  values={{
                    inceptionDate: t(tFormats('date.long'), {
                      value: toDateTime(policy.policyDetail.inceptedAt),
                    }),
                    expiryDate: t(tFormats('date.long'), {
                      value: toDateTime(policy.policyDetail.expiredAt),
                    }),
                  }}
                />
              </Typo>
            )}
          </Box>
        )}
        <Flex width={1} mt={mdBumps}>
          <PrimaryButton width={1} loading={onSubmitAdjustment.loading} type='submit'>
            {t(tKey('actions.continue'))}
          </PrimaryButton>
          <SecondaryButton width={1} ml={xsBumps} onClick={onCancel}>
            {t(tKey('actions.cancel'))}
          </SecondaryButton>
        </Flex>
      </Form>
    </Box>
  )
}
