export * from './date'
export * from './email'
export * from './number'
export * from './ukPhoneNumber'
export * from './year'

export const validateAll =
  <T>(...validators: ((value: T) => boolean)[]) =>
  (value: T): boolean =>
    validators.every((validator) => validator(value))
