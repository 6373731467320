import css from '@styled-system/css'
import styled from 'styled-components'
import {
  color,
  ColorProps,
  compose,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  variant,
} from 'styled-system'

export type MenuBoxProperty = 'default'

export interface MenuBoxProps extends LayoutProps, SpaceProps, ColorProps {
  variant?: MenuBoxProperty
}

export const MenuBox = styled.div<MenuBoxProps>(
  css({
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  variant({
    scale: 'MenuBox',
    variants: {
      default: {},
    },
  }),
  compose(layout, space, color),
)

MenuBox.defaultProps = {
  variant: 'default',
}
