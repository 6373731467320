import { getCoreAsset, getPolicyholder, Proposal, Quote } from '../../../../domain'
import { ChangeConfig, ChangeType } from '../../../../state/configuration'
import { toData } from '../../helpers'

export const toProposalData = (proposal: Proposal) =>
  toQuoteData(proposal.quote, proposal.startAt)

export const toQuoteData = (quote: Quote, startAt?: string) => (change: ChangeConfig) => {
  const { data: profile } = getPolicyholder(quote)
  const { data: asset } = getCoreAsset(quote)
  const { data: version } = quote

  switch (change.changeType) {
    case ChangeType.PROFILE_DISCLOSURE:
      return toData(profile, change.update)
    case ChangeType.ASSET_DISCLOSURE:
      return toData(asset, change.update)
    case ChangeType.QUOTE_DISCLOSURE:
      return toData(version, change.update)
    case ChangeType.START_DATE_CHANGE:
      return startAt
  }
}
