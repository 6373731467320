import { Flex, FlexProps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { Quote } from '../../../../../domain'
import { ProductConfig } from '../../../../../state/configuration'
import { MeteredEstimatedUsage } from '../../../../organisms/pricing'
import { ProposalMeteredBreakdown } from './ProposalMeteredBreakdown'

export interface ProposalMeteredPricingProps extends FlexProps {
  currentQuote: Quote
  product: ProductConfig
}

export const ProposalMeteredPricing: FunctionComponent<ProposalMeteredPricingProps> = ({
  currentQuote,
  product,
  ...props
}) => {
  return (
    <Flex flexDirection='column' justifyContent='flex-start' width={1} {...props}>
      <MeteredEstimatedUsage
        quote={currentQuote}
        variant='prominent'
        borderTopLeftRadius={2}
        borderTopRightRadius={2}
      />
      <ProposalMeteredBreakdown quote={currentQuote} product={product} />
    </Flex>
  )
}
