import React, { FunctionComponent } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Proposal } from '../../../../../domain'
import { AdditionalProfileDisclosureConfig } from '../../../../../state/configuration'
import {
  AdditionalProfileCreateDisclosure,
  AdditionalProfileDisclosure,
} from './components/AdditionalProfileDisclosure'
import { AdditionalProfileSummary } from './components/AdditionalProfileSummary'
import { AdditionalProfilePath } from './path'

export interface DisclosureFormProps {
  section: AdditionalProfileDisclosureConfig
  onComplete: (proposal: Proposal) => void
}

export const AdditionalProfileRouter: FunctionComponent<DisclosureFormProps> = ({
  section,
  onComplete,
}) => (
  <Routes>
    <Route
      index
      element={<AdditionalProfileSummary section={section} onComplete={onComplete} />}
    />
    <Route
      path={AdditionalProfilePath.Profile}
      element={<AdditionalProfileDisclosure section={section} />}
    />
    <Route
      path={AdditionalProfilePath.NewProfile}
      element={<AdditionalProfileCreateDisclosure section={section} />}
    />
    <Route path='*' element={<Navigate to='..' />} />
  </Routes>
)
