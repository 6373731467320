import { Proposal } from './proposal'
import { Quote } from './quote'
import { ReviewType } from './reviewType'

export enum PolicyStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',

  LAPSED = 'LAPSED',
  CANCELLED = 'CANCELLED',
  RENEWED = 'RENEWED',
  VOIDED = 'VOIDED',
}

export interface PolicyVersion {
  quote: Quote
  policyRefNumber: string
  startedAt: string
  createdAt: string
}

export interface PolicyDetail {
  policyPeriodNumber: number
  billingDay: number
  policyStatus: PolicyStatus
  renewalCycle: number

  renewalStopped: boolean
  monthlyBilling: boolean

  renewedPolicyId?: string
  firstInceptedAt: string
  inceptedAt: string
  expiredAt: string
  terminatedAt?: string
}

export interface Policy {
  policyId: string
  policyNumber: string
  schemeCode: string
  reviewType: ReviewType
  proposalCode: string
  upsellProposal?: Proposal
  renewalProposal?: Proposal
  linkedProposals: Proposal[]

  currentPolicyVersion: PolicyVersion
  deferredPolicyVersions: PolicyVersion[]

  policyDetail: PolicyDetail

  timezone: string
}

export interface SourcePolicy {
  schemeCode: string
  policyId: string
  quote: Quote
  detail: PolicyDetail
}

export interface RenewalPolicy extends Policy {
  renewalProposal: Proposal
}
