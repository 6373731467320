import {
  AssetDisclosureChange,
  Data,
  getCoreAsset,
  getPolicyVersion,
  Policy,
  PolicyAdjustmentRequest,
  PolicyChangeType,
  ProfileDisclosureChange,
  QuoteDisclosureChange,
} from '../../../../domain'
import {
  AdjustmentInputConfig,
  AssetChangeConfig,
  ChangeType,
  ProfileChangeConfig,
  QuoteChangeConfig,
} from '../../../../state/configuration'
import { hasUpdate, toUpdates } from '../../helpers'

const toPolicyAssetDisclosureChange =
  (data: Data, policy: Policy) =>
  (
    policyAdjustment: PolicyAdjustmentRequest,
    input: AdjustmentInputConfig<AssetChangeConfig>,
  ): PolicyAdjustmentRequest => {
    const version = getPolicyVersion(policy)
    const { assetCode } = getCoreAsset(version.quote)

    const changes: AssetDisclosureChange[] = toUpdates(input, data).map((update) => ({
      changeType: PolicyChangeType.ASSET_DISCLOSURE_CHANGE,
      assetCode,
      update,
    }))

    return {
      ...policyAdjustment,
      changes: [...policyAdjustment.changes, ...changes],
    }
  }

const toPolicyProfileDisclosureReducer =
  (data: Data) =>
  (
    policyAdjustment: PolicyAdjustmentRequest,
    question: AdjustmentInputConfig<ProfileChangeConfig>,
  ): PolicyAdjustmentRequest => {
    const changes: ProfileDisclosureChange[] = toUpdates(question, data).map(
      (update) => ({
        changeType: PolicyChangeType.PROFILE_DISCLOSURE_CHANGE,
        update,
      }),
    )

    return {
      ...policyAdjustment,
      changes: [...policyAdjustment.changes, ...changes],
    }
  }

const toStartDateChangeReducer =
  (data: Data) =>
  (
    policyAdjustment: PolicyAdjustmentRequest,
    input: AdjustmentInputConfig,
  ): PolicyAdjustmentRequest => {
    return {
      ...policyAdjustment,
      adjustmentType: 'SDA',
      effectiveAt: data[input.name],
    }
  }

const toPolicyQuoteDisclosureReducer =
  (data: Data) =>
  (
    policyAdjustment: PolicyAdjustmentRequest,
    question: AdjustmentInputConfig<QuoteChangeConfig>,
  ): PolicyAdjustmentRequest => {
    const changes: QuoteDisclosureChange[] = toUpdates(question, data).map((update) => ({
      changeType: PolicyChangeType.QUOTE_DISCLOSURE_CHANGE,
      update,
    }))

    return {
      ...policyAdjustment,
      changes: [...policyAdjustment.changes, ...changes],
    }
  }

export const toPolicyAdjustment =
  (inputs: AdjustmentInputConfig[], policy: Policy) =>
  (
    existingData: Data,
    updatedData: Data,
    effectiveAt?: string,
  ): PolicyAdjustmentRequest => {
    const profileDisclosureReducer = toPolicyProfileDisclosureReducer(updatedData)
    const assetDisclosureReducer = toPolicyAssetDisclosureChange(updatedData, policy)
    const quoteDisclosureReducer = toPolicyQuoteDisclosureReducer(updatedData)
    const startDateChangeReducer = toStartDateChangeReducer(updatedData)

    return inputs.filter(hasUpdate(existingData, updatedData)).reduce(
      (policyAdjustment: PolicyAdjustmentRequest, question) => {
        switch (question.adjustment.changeType) {
          case ChangeType.PROFILE_DISCLOSURE:
            return profileDisclosureReducer(
              policyAdjustment,
              question as AdjustmentInputConfig<ProfileChangeConfig>,
            )
          case ChangeType.ASSET_DISCLOSURE:
            return assetDisclosureReducer(
              policyAdjustment,
              question as AdjustmentInputConfig<AssetChangeConfig>,
            )
          case ChangeType.QUOTE_DISCLOSURE:
            return quoteDisclosureReducer(
              policyAdjustment,
              question as AdjustmentInputConfig<QuoteChangeConfig>,
            )
          case ChangeType.START_DATE_CHANGE:
            return startDateChangeReducer(policyAdjustment, question)
          default:
            throw new Error('Invalid adjustment type')
        }
      },
      {
        policyId: policy.policyId,
        adjustmentType: 'MTA',
        changes: [],
        ...(effectiveAt && { effectiveAt }),
      },
    )
  }
