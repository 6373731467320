import React, { FunctionComponent, ReactNode } from 'react'

import {
  Box,
  Flex,
  FlexProps,
  FooterAnchor,
  Icon,
  Typo,
  WideContainer,
  xlBumps,
} from '../atoms'

export interface FooterTemplateProps extends FlexProps {
  content: ReactNode
  poweredBy: ReactNode
  links: Array<{
    href: string
    text: string
  }>
}

export const FooterTemplate: FunctionComponent<FooterTemplateProps> = ({
  content,
  poweredBy,
  links,
  ...props
}) => (
  <Flex
    as='footer'
    flexShrink={0}
    backgroundColor='surfaceFooter'
    py={xlBumps}
    {...props}
  >
    <WideContainer>
      <Box width={1} borderBottomWidth={1} borderColor='dividerFooter' pb={8}>
        <Typo typoSize='sm' color='textFooter'>
          {content}
        </Typo>
      </Box>
      <Flex
        width={1}
        justifyContent='space-between'
        flexDirection={['column-reverse', 'column-reverse', 'row']}
      >
        <Flex width={1} justifyContent={['center', 'center', 'flex-start']} pt={8}>
          <Typo typoSize='sm' color='textFooter'>
            {poweredBy}
          </Typo>
          <Icon icon='poweredByWrisk' color='textFooter' size='logo' ml='6px' mb='1px' />
        </Flex>

        <Flex width={1} justifyContent={['center', 'center', 'flex-end']} pt={8}>
          {links.map(({ href, text }, i) => (
            <Box key={i} mr={i < links.length - 1 ? 5 : 0}>
              <FooterAnchor href={href} target='_blank'>
                {text}
              </FooterAnchor>
            </Box>
          ))}
        </Flex>
      </Flex>
    </WideContainer>
  </Flex>
)
