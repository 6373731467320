import { Flex, mdBumps, Typo } from '@wrisk/ui-components'
import React from 'react'

import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'

const tKey = TKey('components.main-excess')

export const MainExcessesAllEqual: React.FC<{ value: number }> = ({ value }) => {
  const { t } = useWriskTranslation()

  return (
    <Flex
      px={mdBumps}
      py={5}
      variant='raised'
      flexDirection='column'
      alignItems='flex-start'
      width={1}
    >
      <Typo typoSize='xs' mb={1}>
        {t(tKey('header'))}
      </Typo>
      <Typo>{t(tFormats('currency.short'), { amount: value })}</Typo>
    </Flex>
  )
}
