export const UKPhoneNumber = {
  prefix: '+44',
  regex: /^[12][0-9]{8,9}$|^7[0-9]{9}$/,
}

export const isValidPhoneNumber =
  (prefix: string, regex: RegExp) =>
  (text: string | undefined): boolean => {
    const nsn = text?.replace(prefix, '') || ''
    return regex.test(nsn.toString())
  }
