import {
  Box,
  Form,
  mdBumps,
  Section,
  SectionContent,
  SectionTitle,
  SingleSelect,
  TextInput,
  xlBumps,
  xxlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'

import { isRenewalProposal, RenewalProposal } from '../../../../domain'
import { useErrorHandlingAsyncCallback } from '../../../../hooks/errors'
import {
  tEntries,
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { Page, useUpButton } from '../../../../infrastructure/routing'
import { toDateTime } from '../../../../util/date'
import { AppPath } from '../../../AppPath'
import { usePrincipal } from '../../../authentication'
import { ErrorMessage } from '../../../formBuilder'
import { ActionBar } from '../../../organisms/form'
import { FullPage } from '../../../templates'
import { useProduct } from '../../productContext'
import { useProposal } from '../proposalContext'

const tKey = TKey('pages.renewal-opt-out')

interface OptOutFormData {
  reason: string
  other: string
}

const Content: FunctionComponent<{ parent: Page; proposal: RenewalProposal }> = ({
  parent,
  proposal,
}) => {
  const { t } = useWriskTranslation()

  const form = useForm<OptOutFormData>()

  const navigate = useNavigate()
  const upButton = useUpButton(parent)

  const { product } = useProduct()

  const onDismiss = useCallback(() => navigate(parent.url), [navigate])

  const { apiClient } = usePrincipal()

  const onConfirm = useErrorHandlingAsyncCallback(async (data: OptOutFormData) => {
    const renewalStopReason =
      data.reason === 'Other' ? `${data.reason}|${data.other}` : data.reason
    await apiClient.setPolicyRenewalStop(
      proposal.sourcePolicy.policyId,
      renewalStopReason,
    )
    navigate(AppPath.HOME)
  })

  const subheader = (
    <Trans
      t={t}
      i18nKey={tKey('subheader')}
      values={{
        startAt: t(tFormats('date.long'), {
          value: toDateTime(proposal.sourcePolicy.detail.expiredAt),
        }),
      }}
    />
  )

  const options = useMemo(
    () =>
      Object.entries(product.cancellationReasons).reduce(
        (acc, [id, key]) => ({
          ...acc,
          [id]: t(tEntries('cancellationReasons', key)),
        }),
        {},
      ),
    [product.cancellationReasons, t],
  )

  return (
    <FullPage header={t(tKey('header'))} subheader={subheader} upButton={upButton}>
      <FormProvider {...form}>
        <Form formId='opt-out' onSubmit={form.handleSubmit(onConfirm.execute)}>
          <Section mb={xlBumps} width={1}>
            <SectionTitle>{t(tKey('sections', 'reason'))}</SectionTitle>
            <SectionContent>
              <Box width={1} mb={mdBumps}>
                <Controller
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <SingleSelect
                      options={options}
                      onChange={onChange}
                      value={value}
                      name={name}
                    />
                  )}
                  name='reason'
                />
                <ErrorMessage mt={3} name='reason' tName='reason' tKey={tKey} t={t} />
              </Box>
              {form.watch('reason') === 'Other' && (
                <Box width={1}>
                  <Controller
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, name, value } }) => (
                      <TextInput
                        width={[1, 1, 4 / 5]}
                        onChange={onChange}
                        placeholder={t<string>(tKey('inputs', 'other.placeholder'))}
                        name={name}
                        value={value}
                      />
                    )}
                    name='other'
                  />
                  <ErrorMessage mt={3} name='other' tName='other' tKey={tKey} t={t} />
                </Box>
              )}
            </SectionContent>
          </Section>

          <Section mb={xxlBumps} width={1}>
            <SectionTitle>{t(tKey('sections', 'end'))}</SectionTitle>
            <SectionContent>
              <Box width={1} p={mdBumps} variant='raised'>
                {t(tFormats('datetime.medium'), { value: toDateTime(proposal.startAt) })}
              </Box>
            </SectionContent>
          </Section>

          <ActionBar
            destructive
            tKey={tKey}
            loading={onConfirm.loading}
            onDismiss={onDismiss}
          />
        </Form>
      </FormProvider>
    </FullPage>
  )
}

export const RenewalOptOutPage: FunctionComponent<{ parent: Page }> = ({ parent }) => {
  const { proposal } = useProposal()

  return isRenewalProposal(proposal) ? (
    <Content parent={parent} proposal={proposal} />
  ) : (
    <Navigate to={AppPath.HOME} />
  )
}
