import { createContext, useContext } from 'react'

import { AnonymousUser, BackBookTokenRequest } from '../../clients/anonymous'
import { ApiClient } from '../../clients/api'
import { User } from '../../clients/auth'

interface BasePrincipal {
  apiClient: ApiClient
  refreshAnonymous: () => Promise<void>
}

export interface AnonymousPrincipal extends BasePrincipal {
  user: AnonymousUser
  isAuthenticated: false
  isAdmin: false
  backBook: (request: BackBookTokenRequest) => Promise<void>
}

export interface UserPrincipal extends BasePrincipal {
  user: User
  isAuthenticated: true
  isAdmin: false
}

export interface AdminPrincipal extends BasePrincipal {
  user: User
  impersonate: string
  isAuthenticated: true
  isAdmin: true
}

export type Principal = AnonymousPrincipal | UserPrincipal | AdminPrincipal

const PrincipalContext = createContext<Principal | undefined>(undefined)

const PrincipalProvider = PrincipalContext.Provider

export const usePrincipal = (): Principal => {
  const user = useContext(PrincipalContext)
  if (!user) {
    throw new Error('Cannot use PrincipalProvider when undefined.')
  }
  return user
}

export default PrincipalProvider
