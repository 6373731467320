import { Box, BoxProps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { Quote } from '../../../domain'
import { useQuotePremiums } from '../../../hooks/premiums'
import { TExtends, TKey } from '../../../infrastructure/internationalisation'
import { ProductConfig } from '../../../state/configuration'
import { MeteredAdjustedPremium } from './MeteredAdjustedPremium'
import { StandardAdjustedPremium } from './StandardAdjustedPremium'

export interface MeteredAdjustedPremiumsProps extends BoxProps {
  currentQuote: Quote
  previousQuote?: Quote
  product: ProductConfig
}

const tKey = TKey('components.metered-adjusted-premiums')

export const MeteredAdjustedPremiums: FunctionComponent<MeteredAdjustedPremiumsProps> = ({
  currentQuote,
  previousQuote,
  product,
  ...props
}) => {
  const currentPremiums = useQuotePremiums(currentQuote, product)
  const previousPremiums = useQuotePremiums(previousQuote, product)

  return (
    <Box p={2} {...props}>
      <StandardAdjustedPremium
        currentPremiums={currentPremiums}
        previousPremiums={previousQuote && previousPremiums}
        tKey={TExtends(tKey, 'monthly')}
      />
      <MeteredAdjustedPremium
        currentPremiums={currentPremiums}
        previousPremiums={previousQuote && previousPremiums}
        tKey={TExtends(tKey, 'metered')}
      />
    </Box>
  )
}
