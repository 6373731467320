import { Box, Flex, FlexProps, Typo } from '@wrisk/ui-components'
import { t } from 'i18next'
import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import {
  AccrualType,
  getCoreAsset,
  getCoverPremium,
  isCoverActive,
  Quote,
} from '../../../domain'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

const tKey = TKey('components.metered-estimated-usage')

export interface MeteredEstimatedUsageProps extends FlexProps {
  quote: Quote
}

export const MeteredEstimatedUsage: FunctionComponent<MeteredEstimatedUsageProps> = ({
  quote,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const asset = getCoreAsset(quote)

  const annualMileage = asset.data.annualMileage as number | undefined

  const monthlyAmount = getCoverPremium(
    quote.priceBreakdown.filter(isCoverActive),
    AccrualType.MONTHLY,
  )
  const meteredAmount = getCoverPremium(
    quote.priceBreakdown.filter(isCoverActive),
    AccrualType.METERED,
  )

  const mileageEstimatePerMonth =
    monthlyAmount + ((annualMileage || 0) / 12) * meteredAmount

  return (
    <Flex width={1} px={5} py={8} {...props}>
      <Box mr={2}>
        <Typo typoSize='base' fontWeight='bold'>
          {t(tKey('monthlyEstimate'))}
        </Typo>
        <EstimatedMileage quote={quote} {...props} />
      </Box>
      <Typo typoSize='md' fontWeight='bold'>
        {t(tFormats('currency.long'), { amount: mileageEstimatePerMonth })}
      </Typo>
    </Flex>
  )
}

const EstimatedMileage: FunctionComponent<{ quote: Quote }> = ({ quote }) => {
  const asset = getCoreAsset(quote)
  const annualMileage = asset.data.annualMileage as number | undefined

  return (
    <Typo typoSize='xs' mt={1}>
      <Trans
        t={t}
        i18nKey={tKey('estimatedMileage')}
        values={{
          annualMileage: t(tFormats('number.standard'), { amount: annualMileage }),
        }}
      />
    </Typo>
  )
}
