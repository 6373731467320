import {
  Section,
  SectionContent,
  SectionDescription,
  SectionProps,
  SectionTitle,
  useModal,
} from '@wrisk/ui-components'
import { partition } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { UseAsyncReturn } from 'react-async-hook'

import { getCoreAsset, Quote } from '../../../domain'
import { isMainExcess } from '../../../domain/excess'
import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { useProduct } from '../../product/productContext'
import { InRenewalMtaBlockedModal } from '../InRenewalMtaBlockedModal'
import { ExcessModalContent } from './ExcessModalContent'
import { MainExcesses } from './MainExcesses'
import { OtherExcesses } from './OtherExcesses'

interface ExcessSectionProps extends SectionProps {
  quote: Quote
  onSubmitExcess: UseAsyncReturn<void, [{ excess: number }]>
  isMtaAllowed?: boolean
}

const tKey = TKey('components.excess')

export const ExcessSection: React.FC<ExcessSectionProps> = ({
  quote,
  onSubmitExcess,
  isMtaAllowed = true,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const {
    product: {
      excess: { voluntaryExcess },
    },
  } = useProduct()
  const modal = useModal()

  const asset = getCoreAsset(quote)

  const onExcessClick = useCallback(
    (initialExcess: number) => {
      isMtaAllowed
        ? modal.show({
            content: (
              <ExcessModalContent
                onSubmitExcess={onSubmitExcess}
                asset={asset}
                initialExcess={initialExcess}
                onClose={modal.hide}
                excessOptions={voluntaryExcess ?? []}
              />
            ),
          })
        : modal.show({
            content: <InRenewalMtaBlockedModal />,
          })
    },
    [isMtaAllowed, modal, onSubmitExcess, asset, voluntaryExcess],
  )

  const [mainExcesses, otherExcesses] = useMemo(
    () => partition(asset.mandatoryExcessLines, isMainExcess),
    [asset],
  )

  return (
    <Section width={1} {...props}>
      <SectionTitle>{t(tKey('header'))}</SectionTitle>

      <SectionDescription>{t(tKey('subheader'))}</SectionDescription>

      <SectionContent>
        <MainExcesses
          quote={quote}
          mainExcesses={mainExcesses}
          isVoluntaryExcess={!!voluntaryExcess.length}
          currentVoluntaryExcess={asset.voluntaryExcess}
          onExcessClick={onExcessClick}
        />
        {!!otherExcesses.length && <OtherExcesses otherExcesses={otherExcesses} />}
      </SectionContent>
    </Section>
  )
}
