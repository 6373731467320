import {
  Box,
  Flex,
  FlexProps,
  HelpFlexAnchor,
  Icon,
  mdBumps,
  Typo,
  xsBumps,
} from '@wrisk/ui-components'
import { DateTime } from 'luxon'
import React, { FunctionComponent } from 'react'

import { Proposal, RenewalType } from '../../../../../../domain'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../../../infrastructure/internationalisation'

interface CoverDurationSectionProps extends FlexProps {
  proposal: Proposal
}

const tKey = TKey('components.cover-duration')

// TODO (MK): Move to organisms as used in multiple places
export const CoverDurationSection: FunctionComponent<CoverDurationSectionProps> = ({
  proposal,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const startAt = proposal.startAt
    ? (() => {
        const value = DateTime.fromISO(proposal.startAt)
        return value <= DateTime.local()
          ? t(tKey('startAt.today'))
          : t(tFormats('datetime.medium'), { value })
      })()
    : undefined

  return (
    <Box width={1} py={2} variant='raised' {...props}>
      {proposal.monthlyBilling && (
        <HelpFlexAnchor
          href={t<string>('links.paymentFrequency')}
          width={1}
          variant='inset'
          px={mdBumps}
          py={3}
        >
          <Typo>{t(tKey('frequency.header'))}</Typo>
          <Flex>
            <Typo textAlign='right'>{t(tKey('frequency.content'))}</Typo>
            <Icon icon='info' size='tiny' ml={xsBumps} />
          </Flex>
        </HelpFlexAnchor>
      )}

      {startAt && (
        <Flex width={1} px={mdBumps} py={3}>
          <Typo>{t(tKey('startAt.header'))}</Typo>
          <Typo textAlign='right'>{startAt}</Typo>
        </Flex>
      )}

      {proposal.renewalType === RenewalType.AUTO ? (
        <HelpFlexAnchor
          href={t<string>('links.coverDuration')}
          width={1}
          variant='inset'
          px={mdBumps}
          py={3}
        >
          <Typo>{t(tKey('duration.header'))}</Typo>
          <Flex>
            <Typo textAlign='right'>{t(tKey('duration.continuous'))}</Typo>
            <Icon size='tiny' icon='info' ml={xsBumps} />
          </Flex>
        </HelpFlexAnchor>
      ) : proposal.endAt ? (
        <Flex width={1} px={mdBumps} py={3}>
          <Typo>{t(tKey('endAt.header'))}</Typo>
          <Typo textAlign='right'>
            {t(tFormats('datetime.medium'), { value: DateTime.fromISO(proposal.endAt) })}
          </Typo>
        </Flex>
      ) : null}
    </Box>
  )
}
