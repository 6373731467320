import { Box, Flex, Icon, mdBumps, smBumps } from '@wrisk/ui-components'
import React from 'react'

import { Quote } from '../../../../domain'
import { Excess } from '../../../../domain/types/excess'
import { allEqual } from '../../../../util/array'
import { ExcessEndorsements } from '../ExcessEndorsements'
import { VoluntaryExcess } from '../VoluntaryExcess'
import { MainExcessesAllEqual } from './MainExcessesAllEqual'
import { MainExcessesDifferentValues } from './MainExcessesDifferentValues'

export interface ExcessProps {
  quote: Quote
  mainExcesses: Excess[]
  isVoluntaryExcess: boolean
  currentVoluntaryExcess: number
  onExcessClick: (excess: number) => void
}

export const MainExcesses: React.FC<ExcessProps> = ({
  quote,
  mainExcesses,
  isVoluntaryExcess,
  currentVoluntaryExcess,
  onExcessClick,
}) => {
  const excessValues = mainExcesses.map((it) => it.excess)
  const isExcessAllEqual = allEqual(excessValues)

  const excessesDisplay = (
    <>
      <Flex flexDirection={isExcessAllEqual ? ['column', 'row'] : 'column'}>
        {isExcessAllEqual ? (
          <MainExcessesAllEqual value={excessValues[0]} />
        ) : (
          <MainExcessesDifferentValues excesses={mainExcesses} />
        )}
        {isVoluntaryExcess && (
          <>
            <Icon m={smBumps} icon='plus' size='small' />
            <VoluntaryExcess
              currentVoluntaryExcess={currentVoluntaryExcess}
              onClick={onExcessClick}
            />
          </>
        )}
      </Flex>
      <ExcessEndorsements quote={quote} mt={mdBumps} />
    </>
  )

  return <Box width={1}>{excessesDisplay}</Box>
}
