import jwt_decode from 'jwt-decode'

import { Repository } from '../../infrastructure/storage'
import { BackBookTokenRequest, createSessionClient, SessionConfig } from './session'
import { AnonymousUser } from './user'

export interface AnonymousClient {
  signIn: () => Promise<AnonymousUser>
  refresh: () => Promise<AnonymousUser>
  backBook: (request: BackBookTokenRequest) => Promise<AnonymousUser>
  getToken: () => Promise<string>
}

export const createAnonymousClient = (
  config: SessionConfig,
  repository: Repository<string>,
): AnonymousClient => {
  const client = createSessionClient(config)

  const getToken = async () => {
    let token = repository.get()
    if (!token) {
      token = await client.getToken()
      repository.save(token)
    }
    return token
  }

  return {
    getToken,
    signIn: async () => {
      return toAnonymousUser(await getToken())
    },
    refresh: async () => {
      repository.remove()
      return toAnonymousUser(await getToken())
    },
    backBook: async (request: BackBookTokenRequest) => {
      const token = await client.backBookToken({ ...request, brand: config.brand })
      repository.save(token)
      return toAnonymousUser(token)
    },
  }
}

const extractSubject = (token: string) => jwt_decode<{ sub: string }>(token).sub
const toAnonymousUser = (accessToken: string) => ({ userId: extractSubject(accessToken) })
