import { isNil } from 'lodash'

const YearRegex = /^[12][0-9]{3}$/
const PartialYearRegex = /^(?:[12][0-9]{0,3})?$/

export const isValidCompleteYear = (year: number | null | undefined): boolean =>
  !isNil(year) && !!YearRegex.exec(year.toString())

export const isValidPartialYearString = (year: string): boolean =>
  !!PartialYearRegex.exec(year.toString())
