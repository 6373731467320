import { SearchViewWithConfirmation } from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'

import { Address } from '../../../domain'
import { AddressSearch, AddressSearchProps } from './AddressSearch'

export interface AddressLookupProps extends AddressSearchProps {
  value?: Address
}

export const AddressLookup: FunctionComponent<AddressLookupProps> = ({
  value,
  onChange,
  ...props
}) => {
  const renderConfirmationLabel = useCallback(
    ({ pcaId, label }: Address) => <pre key={pcaId}>{label}</pre>,
    [],
  )

  const renderSearch = useCallback(
    (f) => <AddressSearch onChange={f} {...props} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange],
  )

  return (
    <SearchViewWithConfirmation
      value={value}
      onChange={onChange}
      renderConfirmation={renderConfirmationLabel}
      renderSearch={renderSearch}
    />
  )
}
