import { Box, BoxProps, Notification, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { CustomerAccount, PaymentMethodType } from '../../../domain'
import { useErrorHandlingAsyncCallback } from '../../../hooks/errors'
import {
  TKey,
  tPaymentMethods,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { ProductKind } from '../../../state/configuration'
import { usePrincipal } from '../../authentication'
import {
  useCustomerAccount,
  useNextStatement,
  usePaymentMethods,
} from '../../product/policy/billing'
import { useProduct } from '../../product/productContext'
import { BillingDay } from './BillingDay'
import { PaymentMethodBACS } from './PaymentMethodBACS'
import { PaymentMethodCard } from './PaymentMethodCard'

const tKey = TKey('components.payment-settings')

export const PaymentSettings: FunctionComponent<BoxProps> = (props) => {
  const { t } = useWriskTranslation()

  const { apiClient } = usePrincipal()

  const { paymentMethods } = usePaymentMethods()
  const { customerAccount, setCustomerAccount } = useCustomerAccount()
  const { setNextStatement } = useNextStatement()

  const { loading, execute: onUpdate } = useErrorHandlingAsyncCallback(
    async (customerAccount: CustomerAccount) => {
      setCustomerAccount(customerAccount)
      setNextStatement(await apiClient.getNextStatement(customerAccount.accountId))
    },
  )

  const { product } = useProduct()

  const billingMethodComponent = (() => {
    switch (customerAccount.paymentMethodType) {
      case PaymentMethodType.BACS_DEBIT:
        return (
          <PaymentMethodBACS
            tKey={tKey}
            customerAccount={customerAccount}
            paymentMethods={paymentMethods}
          />
        )
      case PaymentMethodType.CARD:
        return (
          <PaymentMethodCard
            tKey={tKey}
            customerAccount={customerAccount}
            paymentMethods={paymentMethods}
          />
        )
    }
  })()

  const billingDayComponent = (() => {
    switch (product.productKind) {
      case ProductKind.SUBSCRIPTION:
      case ProductKind.METERED:
        return (
          <BillingDay
            tKey={tKey}
            customerAccount={customerAccount}
            onUpdate={onUpdate}
            loading={loading}
          />
        )
      default:
        return null
    }
  })()

  return (
    <Box p={2} {...props}>
      {billingMethodComponent}
      {billingDayComponent}
      <Notification layout='small' mt={2}>
        <Typo typoSize='sm'>
          {t(tKey('notification'), {
            statement: t(tPaymentMethods('statement')),
          })}
        </Typo>
      </Notification>
    </Box>
  )
}
