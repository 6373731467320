import { FlexProps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { Proposal, Quote } from '../../../../domain'
import { ProductConfig, ProductKind } from '../../../../state/configuration'
import {
  AnnualAdjustedPremium,
  DriveawayAdjustedPremium,
  MeteredAdjustedPremiums,
  SubscriptionAdjustedPremium,
} from '../../../organisms/pricing'

export interface PricingDetailedFactoryProps extends FlexProps {
  product: ProductConfig
  proposal: Proposal
  quote: Quote
}

export const ProposalSummaryPricing: FunctionComponent<PricingDetailedFactoryProps> = ({
  product,
  proposal,
  quote,
  ...props
}) => {
  switch (product.productKind) {
    case ProductKind.ANNUAL:
      return (
        <AnnualAdjustedPremium
          width={1}
          variant='raised'
          currentQuote={quote}
          product={product}
          {...props}
        />
      )
    case ProductKind.SUBSCRIPTION:
      return (
        <SubscriptionAdjustedPremium
          width={1}
          variant='raised'
          currentQuote={quote}
          product={product}
          {...props}
        />
      )
    case ProductKind.DRIVEAWAY:
      return (
        <DriveawayAdjustedPremium
          width={1}
          variant='raised'
          currentQuote={quote}
          product={product}
          policyTerm={proposal.policyTerm}
          {...props}
        />
      )
    case ProductKind.METERED:
      return (
        <MeteredAdjustedPremiums
          width={1}
          variant='raised'
          p={2}
          currentQuote={quote}
          product={product}
          {...props}
        />
      )
  }
}
