import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { generatePath, Navigate, useNavigate, useParams } from 'react-router-dom'

import { SideBarPage } from '../../../../../BasePage/SideBarPage'
import { Proposal } from '../../../../../domain'
import { useRelevantDisclosures } from '../../../../../hooks/adjustments/proposal/disclosure/relevantDisclosures'
import { ManagementType } from '../../../../../state/configuration'
import { AppPath } from '../../../../AppPath'
import { useProduct } from '../../../productContext'
import { AdditionalProfileRouter } from '../AdditionalProfileRouter'
import { DisclosureSection } from './components/DisclosureSection'
import { PolicyholderSection } from './components/PolicyholderSection'
import { ProgressBar } from './components/ProgressBar'
import { PromotionSection } from './components/PromotionSection'
import { DisclosurePath } from './path'

export interface DisclosurePageProps {
  onComplete: (proposal: Proposal) => void
}

export const DisclosurePage: FunctionComponent<DisclosurePageProps> = ({
  onComplete,
}) => {
  const navigate = useNavigate()
  const { sectionId } = useParams()

  const {
    product: { disclosures },
  } = useProduct()
  const relevantDisclosures = useRelevantDisclosures(disclosures)

  const { sectionIndex, currentSection, nextSection } = useMemo(() => {
    const sectionIndex = relevantDisclosures.findIndex(
      (section) => section.id === sectionId,
    )

    const [currentSection, nextSection] =
      sectionIndex >= 0 ? relevantDisclosures.slice(sectionIndex) : []

    return {
      sectionIndex,
      currentSection,
      nextSection,
    }
  }, [relevantDisclosures, sectionId])

  const onCompleteCallback = useCallback(
    (proposal: Proposal) => {
      if (nextSection) {
        navigate(
          `../${generatePath(DisclosurePath.Section, { sectionId: nextSection.id })}`,
        )
      } else {
        onComplete(proposal)
      }
    },
    [nextSection, navigate, onComplete],
  )

  const onCompleteCallbackPolicyholder = useCallback(() => {
    if (nextSection) {
      navigate(
        `../${generatePath(DisclosurePath.Section, { sectionId: nextSection.id })}`,
      )
    }
  }, [nextSection, navigate])

  if (!currentSection) {
    return <Navigate to={AppPath.HOME} />
  }

  const component = (() => {
    switch (currentSection.type) {
      case ManagementType.ADDITIONAL_PROFILE:
        return (
          <AdditionalProfileRouter
            key={sectionId}
            section={currentSection}
            onComplete={onCompleteCallback}
          />
        )
      case ManagementType.POLICYHOLDER:
        return (
          <PolicyholderSection
            key={sectionId}
            section={currentSection}
            onComplete={onCompleteCallbackPolicyholder}
          />
        )
      case ManagementType.PROMOTION:
        return (
          <PromotionSection
            key={sectionId}
            section={currentSection}
            onComplete={onCompleteCallback}
          />
        )
      default:
        return (
          <DisclosureSection
            key={sectionId}
            section={currentSection}
            isLastSection={sectionIndex === relevantDisclosures.length - 1}
            onComplete={onCompleteCallback}
          />
        )
    }
  })()

  return <SideBarPage sideContent={<ProgressBar />}>{component}</SideBarPage>
}
