import React, { ReactElement, ReactNode, useCallback } from 'react'

import { useNotifyingState } from '../../extensions'
import { ConfirmationView } from './ConfirmationView'

export interface InputConfirmationFormProps<T> {
  value?: T
  renderConfirmation: (value: T) => ReactNode | null
  renderSearch: (onChange: (value: T) => void) => ReactElement
  onChange: (value: T | undefined) => void
}

export const SearchViewWithConfirmation = <T,>({
  value,
  renderConfirmation,
  renderSearch,
  onChange,
}: InputConfirmationFormProps<T>): ReactElement => {
  const [internalValue, setValue] = useNotifyingState(value, [onChange])

  const onInternalChange = useCallback(() => setValue(undefined), [setValue])

  return internalValue ? (
    <ConfirmationView
      onChange={onInternalChange}
      label={renderConfirmation(internalValue)}
    />
  ) : (
    renderSearch(setValue)
  )
}
