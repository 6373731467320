import {
  Box,
  Flex,
  FlexAnchor,
  FlexAnchorProps,
  Icon,
  lgBumps,
  mdBumps,
  ModalHeader,
  Typo,
  useModal,
} from '@wrisk/ui-components'
import { TFunction } from 'i18next'
import React, { FunctionComponent, useCallback } from 'react'
import {
  ArrayPath,
  Controller,
  FieldValues,
  useFieldArray,
  useFormContext,
} from 'react-hook-form'

import { tDefaults, TKeyBuilder } from '../../../infrastructure/internationalisation'
import { InputConfig } from '../../../state/configuration'
import { getFormatter } from '../maps'
import { QuestionLoopInputFormMeta } from './QuestionLoop'

export interface FormReadOnlyProps extends Omit<FlexAnchorProps, 'ref' | 'onClick'> {
  input: InputConfig
  tKey: TKeyBuilder
  t: TFunction
}

export interface ReadOnlyInputProps extends Omit<FlexAnchorProps, 'ref' | 'onClick'> {
  input: InputConfig
  onClick: () => void
  tKey: TKeyBuilder
  t: TFunction
}

export const ReadOnlyInput: FunctionComponent<ReadOnlyInputProps> = ({
  input,
  onClick,
  tKey,
  t,
  ...props
}) => {
  const { control } = useFormContext()

  const Formatter = getFormatter(input.type)

  return (
    <FlexAnchor
      backgroundColor='surfaceDisabled'
      p={mdBumps}
      onClick={onClick}
      {...props}
    >
      <Typo fontFamily='input' color='textDisabled'>
        <Controller
          name={input.name}
          control={control}
          render={({ field: { value } }) => (
            <Formatter value={value} input={input} t={t} />
          )}
        />
      </Typo>
      <Icon icon='info' size='tiny' ml={lgBumps} />
    </FlexAnchor>
  )
}

export const ReadOnlyInputLoop: FunctionComponent<ReadOnlyInputProps> = ({
  input,
  onClick,
  tKey,
  t,
  ...props
}) => {
  const { control } = useFormContext()
  const { fields } = useFieldArray<FieldValues, ArrayPath<FieldValues>, any>({
    name: input.name,
    control,
  })

  const { inputs } = input.meta as QuestionLoopInputFormMeta

  if (fields.length === 0) {
    return (
      <FlexAnchor
        backgroundColor='surfaceDisabled'
        p={mdBumps}
        onClick={onClick}
        {...props}
      >
        <Typo fontFamily='input' color='textDisabled'>
          {t(tDefaults('inputs.none'))}
        </Typo>
        <Icon icon='info' size='tiny' ml={lgBumps} />
      </FlexAnchor>
    )
  }

  const elements = fields.map((field, index) => (
    <Flex
      key={field.id}
      borderBottomWidth={index === fields.length - 1 ? 0 : 1}
      width={1}
    >
      <Box p={mdBumps}>
        {inputs?.map((it, inputIndex) => {
          const Formatter = getFormatter(it.type)
          return (
            <Controller
              key={`${field.id}-${inputIndex}`}
              control={control}
              name={`${input.name}.${index}.${it.name}`}
              defaultValue={field?.[it.name]}
              render={({ field: { value } }) => (
                <Typo fontFamily='input' color='textDisabled'>
                  <Formatter input={it} value={value} t={t} />
                </Typo>
              )}
            />
          )
        })}
      </Box>
      <Icon icon='info' size='tiny' ml={lgBumps} mr={mdBumps} />
    </Flex>
  ))

  return (
    <FlexAnchor
      width={1}
      backgroundColor='surfaceDisabled'
      flexDirection='column'
      alignItems='flex-start'
      onClick={onClick}
      {...props}
    >
      {elements}
    </FlexAnchor>
  )
}

export const FormReadOnly: FunctionComponent<FormReadOnlyProps> = ({
  input,
  tKey,
  t,
  ...props
}) => {
  const modal = useModal()

  const onClick = useCallback(() => {
    modal.show({
      content: (
        <Box>
          <ModalHeader header={t('defaults.inputs.readonly.header')} />
          <Typo>{t('defaults.inputs.readonly.content')}</Typo>
        </Box>
      ),
    })
  }, [modal])

  const Element = input.type === 'questionLoop' ? ReadOnlyInputLoop : ReadOnlyInput

  return <Element input={input} onClick={onClick} tKey={tKey} t={t} {...props} />
}
