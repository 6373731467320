import React, { FunctionComponent } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { ConfigurationState, useConfig } from '../../state/configuration'
import { AppPath } from '../AppPath'
import { RetrievePage } from './RetrievePage'
import { RetrievePath } from './RetrievePath'
import { RetrieveProposalPage } from './RetrieveProposalPage'

const getBackBooks = (state: ConfigurationState) => state.config.backBooks

export const RetrieveRouter: FunctionComponent = () => {
  const backBooks = useConfig(getBackBooks)

  return (
    <Routes>
      <Route index element={<RetrievePage tName='saveMyQuote' />} />

      {backBooks?.map((backBook) => (
        <Route
          key={backBook}
          path={backBook}
          element={<RetrievePage tName={backBook} />}
        />
      ))}

      <Route path={RetrievePath.RETRIEVE_PROPOSAL} element={<RetrieveProposalPage />} />

      <Route path='*' element={<Navigate to={AppPath.HOME} />} />
    </Routes>
  )
}
