import { compact, isNil } from 'lodash'

import { toFormattedDate } from '../util/date'
import { Specification } from './types'

export const toVehicleDescription = ({ make, model, trim }: Specification): string =>
  compact([make, model, trim]).join(' ')

export const toVehicleFuelDescription = ({
  primaryFuel,
  secondaryFuel,
}: Specification): string =>
  secondaryFuel ? `${primaryFuel}/${secondaryFuel}` : primaryFuel

export const toVehicleYearRange = ({
  rangeStartYear,
  rangeEndYear,
}: Specification): string => `${rangeStartYear}-${rangeEndYear ?? 'Present'}`

export const toVehicleManufacturePeriod = ({
  startDate,
  endDate,
}: Specification): string | undefined =>
  startDate && endDate
    ? `${toFormattedDate(startDate)} to ${toFormattedDate(endDate)}`
    : startDate
    ? `${toFormattedDate(startDate)} onwards`
    : undefined

const toEngineCapacityInLitres = (capacity: number) =>
  `${(Math.round(capacity / 100.0) / 10).toFixed(1)}-litre`

export const toVehicleEngine = ({
  engineCapacity,
  engineLabel,
}: Specification): string | undefined => {
  if (!isNil(engineCapacity)) {
    const capacityInLitres = toEngineCapacityInLitres(engineCapacity)
    return engineLabel !== undefined
      ? `${capacityInLitres} ${engineLabel}`
      : capacityInLitres
  } else if (!isNil(engineLabel)) {
    return engineLabel
  } else {
    return undefined
  }
}

export const toVehicleEnginePower = ({
  enginePower,
}: Specification): string | undefined =>
  enginePower ? `${Math.round(enginePower * 1.01387)} PS` : undefined
