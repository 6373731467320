import React, { FunctionComponent, useMemo } from 'react'
import { Route, Routes } from 'react-router-dom'

import { useIsMtaAllowed } from '../../../../hooks/adjustments/policy/renewal'
import { useCurrentPath } from '../../../../infrastructure/routing'
import { AppPath } from '../../../AppPath'
import { BillingManager } from '../billing'
import { CancellationRouter } from '../cancellation'
import { CancelConfirmationPage } from '../cancellation/CancelConfirmationPage'
import { PolicyTabs } from '../configuration'
import { AddOnsRouter } from '../covers'
import { ExcessRouter } from '../excess'
import { PolicyManagementRouter } from '../management'
import { PolicyPath } from '../PolicyPath'
import { UploadRouter } from '../upload'

export const PolicyActiveRoutes: FunctionComponent = () => {
  const isMtaAllowed = useIsMtaAllowed()
  const currentPath = useCurrentPath()

  const policyPage = useMemo(() => ({ url: currentPath, id: 'policy' }), [currentPath])
  const documentsPage = useMemo(
    () => ({ url: [currentPath, PolicyPath.DOCUMENTS].join('/'), id: 'documents' }),
    [currentPath],
  )

  return (
    <BillingManager>
      <Routes>
        <Route
          key='cancel'
          path={PolicyPath.CANCEL + AppPath.WILDCARD}
          element={<CancellationRouter parent={policyPage} />}
        />

        <Route
          key='cancellation-confirmation'
          path={PolicyPath.CANCEL_CONFIRMATION}
          element={<CancelConfirmationPage />}
        />

        <Route
          key='upload'
          path={PolicyPath.UPLOADS + AppPath.WILDCARD}
          element={<UploadRouter parent={documentsPage} />}
        />

        {isMtaAllowed && (
          <Route
            key='adjustment'
            path={PolicyPath.MANAGEMENT + AppPath.WILDCARD}
            element={<PolicyManagementRouter parent={policyPage} />}
          />
        )}

        {isMtaAllowed && (
          <Route
            key='excess'
            path={PolicyPath.EXCESS + AppPath.WILDCARD}
            element={<ExcessRouter parent={policyPage} />}
          />
        )}

        {isMtaAllowed && (
          <Route
            key='add-ons'
            path={PolicyPath.ADD_ONS + AppPath.WILDCARD}
            element={<AddOnsRouter parent={policyPage} />}
          />
        )}

        <Route path='*' element={<PolicyTabs />} />
      </Routes>
    </BillingManager>
  )
}
