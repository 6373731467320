import { Box, Flex, Heading, mdBumps, smBumps, Typo } from '@wrisk/ui-components'
import React from 'react'

import { Excess } from '../../../domain/types/excess'
import {
  tExcesses,
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

export interface OtherExcessProps {
  otherExcesses: Excess[]
}

const tKey = TKey('components.other-excesses')

export const OtherExcesses: React.FC<OtherExcessProps> = ({ otherExcesses }) => {
  const { t } = useWriskTranslation()

  const heading = (
    <Box my={smBumps}>
      <Heading typoSize='md'>{t(tKey('header'))}</Heading>
    </Box>
  )

  const excesses = otherExcesses.map((it) => (
    <Flex key={it.excessCategory} justifyContent='space-between' width={1} py={3}>
      <Typo>{t(tExcesses(it.excessCategory, 'name'))}</Typo>
      <Typo>{t(tFormats('currency.short'), { amount: it.excess })}</Typo>
    </Flex>
  ))

  return (
    <Box mt={mdBumps} width={1}>
      {heading}
      <Flex px={mdBumps} py={2} variant='raised' flexDirection='column' width={1}>
        {excesses}
      </Flex>
    </Box>
  )
}
