import {
  BooleanInput,
  Box,
  Flex,
  Heading,
  mdBumps,
  PrimaryButton,
  SecondaryButton,
  smBumps,
  Typo,
} from '@wrisk/ui-components'
import { TFunction } from 'i18next'
import { isNil } from 'lodash'
import React, { FunctionComponent, useCallback, useState } from 'react'
import { useAsyncCallback } from 'react-async-hook'
import {
  ArrayPath,
  Controller,
  FieldValues,
  useFieldArray,
  useFormContext,
} from 'react-hook-form'

import { TExtends, TKeyBuilder } from '../../../infrastructure/internationalisation'
import { InputConfig } from '../../../state/configuration'
import { getFormatter, getInput } from '../maps'
import { getValidationRules } from '../validation'
import { ErrorMessage } from './ErrorMessage'

export type QuestionLoopInputFormMeta = Record<string, any> & {
  inputs?: Array<InputConfig>
}

interface QuestionLoopInputFormProps {
  name: string
  type: string
  meta: QuestionLoopInputFormMeta
  validation: Record<string, any> | undefined
  errorMessageClass?: string

  tKey: TKeyBuilder
  t: TFunction
}

export const QuestionLoop: FunctionComponent<QuestionLoopInputFormProps> = ({
  name,
  type,
  validation,
  meta: { inputs },
  errorMessageClass,
  tKey,
  t,
}) => {
  const { control, trigger, watch } = useFormContext()
  const { fields, append, remove, move } = useFieldArray<
    FieldValues,
    ArrayPath<FieldValues>,
    any
  >({
    name,
    control,
  })

  const watchedValue = watch(name)
  const [hasAnswers, setHasAnswers] = useState(
    isNil(watchedValue) ? undefined : Boolean(watchedValue.length),
  )
  const [showInputs, setShowInputs] = useState(false)

  const onChangeHasAnswers = useCallback(
    (onChange: (it: boolean) => void) => (answer: boolean) => {
      setHasAnswers(answer)

      if (!answer) {
        remove()
        setShowInputs(false)
      } else if (fields.length === 0) {
        const blankFields =
          inputs?.reduce((acc, item) => ({ ...acc, [item.name]: undefined }), {}) ?? {}
        append(blankFields)
        setShowInputs(true)
      }

      onChange(answer)
    },
    [fields, remove, inputs, append],
  )

  const onAppend = useAsyncCallback(async () => {
    const isValid = await trigger(
      inputs?.map((input) => `${name}.${fields.length - 1}.${input.name}`),
    )

    if (isValid) {
      append({})
      setShowInputs(true)
    }
  })

  const onConfirm = useAsyncCallback(async () => {
    const isValid = await trigger(
      inputs?.map((input) => `${name}.${fields.length - 1}.${input.name}`),
    )

    if (isValid) {
      move(fields.length - 1, fields.length - 1)
      setShowInputs(false)
    }
  })

  const onCancel = useCallback(() => {
    setShowInputs(false)
    if (fields.length === 1) {
      setHasAnswers(false)
    }
    remove(fields.length - 1)
  }, [fields, remove, setHasAnswers, setShowInputs])

  const onRemove = useCallback(
    (index: number) => () => {
      if (fields.length === 1) {
        setHasAnswers(false)
      }
      remove(index)
    },
    [fields, remove, setHasAnswers],
  )

  const summaryFields = showInputs ? fields.slice(0, fields.length - 1) : fields
  const inputField = fields[fields.length - 1]

  return (
    <Box>
      <Box mb={mdBumps}>
        <Controller
          name={`questionLoops-${name}`}
          control={control}
          defaultValue={hasAnswers}
          rules={{
            validate: getValidationRules(type, validation ?? {}),
          }}
          render={({ field: { name, onChange } }) => (
            <BooleanInput
              name={name}
              value={hasAnswers}
              onSelect={onChangeHasAnswers(onChange)}
            />
          )}
        />

        <ErrorMessage
          name={`questionLoops-${name}`}
          tKey={tKey}
          t={t}
          tName='required'
          pt={smBumps}
          className={errorMessageClass}
        />
      </Box>

      {Boolean(summaryFields.length) && (
        <Flex
          flexDirection='column'
          alignItems='flex-start'
          variant='raised'
          mb={mdBumps}
        >
          {summaryFields.map((field, index) => (
            <Flex
              key={field.id}
              borderBottomWidth={index === fields.length - 1 ? 0 : 1}
              width={1}
              p={mdBumps}
            >
              <Box pr={2}>
                {inputs?.map((input, inputIndex) => {
                  const Formatter = getFormatter(input.type)
                  return (
                    <Controller
                      key={`${field.id}-${inputIndex}`}
                      control={control}
                      name={`${name}.${index}.${input.name}`}
                      defaultValue={field?.[input.name]}
                      render={({ field: { value } }) => (
                        <Typo fontFamily='input'>
                          <Formatter input={input} value={value} t={t} />
                        </Typo>
                      )}
                    />
                  )
                })}
              </Box>
              <Box>
                <SecondaryButton layout='small' onClick={onRemove(index)}>
                  Remove
                </SecondaryButton>
              </Box>
            </Flex>
          ))}
        </Flex>
      )}

      {fields.length > 0 && !showInputs && (
        <PrimaryButton
          type='button'
          onClick={onAppend.execute}
          loading={onAppend.loading}
          mb={mdBumps}
        >
          Add another
        </PrimaryButton>
      )}

      {showInputs && (
        <Box key={inputField.id} px={mdBumps} borderWidth={1}>
          {inputs?.map(({ type, name: inputName, meta, validation }, index) => {
            const Input = getInput(type)
            return (
              <Box key={`${inputField?.id}-${index}`}>
                <Heading my={mdBumps}>
                  {t(tKey(name, 'inputs', inputName, 'header'))}
                </Heading>

                <Controller
                  control={control}
                  name={`${name}.${fields.length - 1}.${inputName}`}
                  defaultValue={inputField?.[inputName]}
                  rules={{
                    validate: getValidationRules(type, validation ?? {}),
                  }}
                  render={({ field: { name: fieldName, value, onChange, onBlur } }) => (
                    <Input
                      name={fieldName}
                      onChange={onChange}
                      value={value}
                      onBlur={onBlur}
                      meta={meta}
                      validation={validation}
                      tKey={TExtends(tKey, name, 'inputs')}
                      tName={inputName}
                      t={t}
                    />
                  )}
                />

                <ErrorMessage
                  name={`${name}.${fields.length - 1}.${inputName}`}
                  tKey={TExtends(tKey, name, 'inputs')}
                  t={t}
                  tName={inputName}
                  className={errorMessageClass}
                  pt={smBumps}
                />
              </Box>
            )
          })}

          <Box my={mdBumps}>
            <PrimaryButton
              onClick={onConfirm.execute}
              loading={onConfirm.loading}
              mr={2}
              type='button'
            >
              Confirm
            </PrimaryButton>
            <SecondaryButton onClick={onCancel} type='button'>
              Cancel
            </SecondaryButton>
          </Box>
        </Box>
      )}
    </Box>
  )
}
