import {
  ExpandableSection,
  PremiumBreakdown,
  PremiumLineItem,
  PremiumSummary,
  smBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useMemo } from 'react'
import { Trans } from 'react-i18next'

import { QuotePremiums } from '../../../hooks/premiums'
import {
  tFormats,
  TKeyBuilder,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

export interface MeteredAdjustedPremiumProps {
  currentPremiums: QuotePremiums
  previousPremiums?: QuotePremiums
  tKey: TKeyBuilder
}

export const MeteredAdjustedPremium: FunctionComponent<MeteredAdjustedPremiumProps> = ({
  currentPremiums,
  previousPremiums,
  tKey,
}) => {
  const { t } = useWriskTranslation()

  const ipt = (
    <Trans
      t={t}
      i18nKey={tKey('ipt')}
      components={{
        abbr: <abbr />,
      }}
    />
  )

  const header = (
    <PremiumSummary
      header={t(tKey('header'))}
      currentAmount={t(tFormats('currency.decimal'), {
        amount: currentPremiums.breakdown.metered,
      })}
      previousAmount={
        previousPremiums &&
        t<string>(tFormats('currency.decimal'), {
          amount: previousPremiums.breakdown.metered,
        })
      }
    />
  )

  const lineItems = useMemo<PremiumLineItem[]>(
    () => [
      {
        header: t(tKey('core')),
        currentAmount: t(tFormats('currency.decimal'), {
          amount: currentPremiums.breakdown.metered,
        }),
        previousAmount:
          previousPremiums &&
          t<string>(tFormats('currency.decimal'), {
            amount: previousPremiums.breakdown.metered,
          }),
      },
    ],
    [currentPremiums, previousPremiums, t, tKey],
  )

  return (
    <ExpandableSection header={header} reversed>
      <PremiumBreakdown lineItems={lineItems} ipt={ipt} mx={smBumps} />
    </ExpandableSection>
  )
}
