import { FooterTemplate, xxlBumps } from '@wrisk/ui-components'
import React, { FunctionComponent, useMemo } from 'react'

import { TKey, useWriskTranslation } from '../../infrastructure/internationalisation'

const tKey = TKey('components.footer')

export const Footer: FunctionComponent = () => {
  const { t } = useWriskTranslation()

  const links = useMemo(
    () => [
      {
        href: t('links.privacy-policy'),
        text: t(tKey('privacy-policy')),
      },
      {
        href: t('links.business-terms'),
        text: t(tKey('business-terms')),
      },
    ],
    [t],
  )

  return (
    <FooterTemplate
      content={t(tKey('content'))}
      poweredBy={t(tKey('powered-by'))}
      links={links}
      mt={xxlBumps}
    />
  )
}
