import { Box, SectionContent, SectionTitle, Typo, xxlBumps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { isCoverActive, Quote } from '../../../domain'
import { useIncludedCovers, useOptionalCovers } from '../../../hooks/covers'
import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { useProduct } from '../../product/productContext'
import { ExcessExpandable, ExclusionsExpandable } from '../excess'
import { IncludedCoversExpandable } from './IncludedCoversExpandable'
import { OptionalCoversExpandable } from './OptionalCoversExpandable'

const tKey = TKey('components.your-cover')

export interface YourCoverSectionProps {
  quote: Quote
}

export const YourCoverSection: FunctionComponent<YourCoverSectionProps> = ({ quote }) => {
  const { t } = useWriskTranslation()

  const { product } = useProduct()

  const includedCovers = useIncludedCovers(quote, product)
  const optionalCovers = useOptionalCovers(quote, product).filter(isCoverActive)

  return (
    <Box width={1} mb={xxlBumps}>
      <SectionTitle>{t(tKey('header'))}</SectionTitle>
      <SectionContent>
        <Box width={1} p={2} variant='raised'>
          <IncludedCoversExpandable
            reversed
            covers={includedCovers}
            header={<Typo fontWeight='bold'>{t(tKey('headings.core-covers'))}</Typo>}
          />
          <OptionalCoversExpandable
            reversed
            covers={optionalCovers}
            showPremiums={true}
            header={<Typo fontWeight='bold'>{t(tKey('headings.optional-covers'))}</Typo>}
          />
          {product.excess && (
            <ExcessExpandable
              reversed
              quote={quote}
              header={<Typo fontWeight='bold'>{t(tKey('headings.excesses'))}</Typo>}
            />
          )}
          <ExclusionsExpandable
            reversed
            header={<Typo fontWeight='bold'>{t(tKey('headings.exclusions'))}</Typo>}
          />
        </Box>
      </SectionContent>
    </Box>
  )
}
