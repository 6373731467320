import { isNil, isUndefined } from 'lodash'

export const isValidNumber = (value: string): boolean => !!/^[0-9]*$/.exec(value)

export const isLessThanOrEqual =
  (check: number | undefined) =>
  (value: number | null | undefined): boolean =>
    isUndefined(check) || (!isNil(value) && value <= check)

export const isGreaterThanOrEqual =
  (check: number | undefined) =>
  (value: number | null | undefined): boolean =>
    isUndefined(check) || (!isNil(value) && value >= check)
