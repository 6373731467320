import { SectionProps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { getCurrentQuote } from '../../../../domain'
import { useProposalExcessAdjustment } from '../../../../hooks/adjustments/proposal'
import { ExcessSection } from '../../../organisms/excess'
import { useProduct } from '../../productContext'
import { useProposal } from '../proposalContext'

export const ProposalExcessSection: FunctionComponent<SectionProps> = (props) => {
  const { proposal } = useProposal()
  const { product } = useProduct()

  const quote = getCurrentQuote(proposal)

  const onSubmitExcess = useProposalExcessAdjustment()

  return product.excess ? (
    <ExcessSection quote={quote} onSubmitExcess={onSubmitExcess} {...props} />
  ) : null
}
