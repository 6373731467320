import { useMemo } from 'react'

import {
  AccrualType,
  CoverType,
  getCovers,
  getCoversByType,
  isIncludedCoverActive,
  PriceOption,
  Quote,
} from '../domain'
import { getCoreAssetType, ProductConfig } from '../state/configuration'

export interface IncludedCover {
  coverCode: string
  active: boolean
}

export const useIncludedCovers = (
  quote: Quote,
  product: ProductConfig,
): IncludedCover[] =>
  useMemo(() => {
    const { covers } = getCoreAssetType(product)

    return covers.included.map((included) => ({
      coverCode: included.code,
      active: isIncludedCoverActive(quote, included),
    }))
  }, [product, quote])

export const useMainCovers = (quote: Quote | undefined): PriceOption[] =>
  useMemo(() => {
    if (!quote) return []

    return getCoversByType(quote.priceBreakdown, CoverType.MAIN)
  }, [quote])

export const useOptionalCovers = (
  quote: Quote | undefined,
  product: ProductConfig,
): PriceOption[] =>
  useMemo(() => {
    if (!quote) return []

    const { covers } = getCoreAssetType(product)

    return (covers.optional ?? []).flatMap((optional) =>
      getCovers(quote.priceBreakdown, AccrualType.MONTHLY, optional.code),
    )
  }, [product, quote])

export const useDiscountCovers = (quote: Quote | undefined): PriceOption[] =>
  useMemo(() => {
    if (!quote) return []

    return getCoversByType(quote.priceBreakdown, CoverType.DISCOUNT)
  }, [quote])
