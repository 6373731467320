import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { InputConfig } from '../../state/configuration'
import useConditions from './conditions'

export interface ConfiguredInput {
  id: string
  input: InputConfig
  isVisible: boolean
  isReadOnly: boolean
}

export const useConfiguredInputs = (inputs: InputConfig[]): ConfiguredInput[] => {
  const { unregister } = useFormContext()
  const conditions = useConditions(inputs)

  return useMemo(
    () =>
      inputs.map((input) => {
        const { name, conditional, readOnly } = input

        const isVisible = conditional?.conditions.every(conditions) ?? true
        const isReadOnly = readOnly?.conditions.every(conditions) ?? false

        if (!isVisible && !conditional?.preserve) {
          unregister(name)
        }

        return { id: name, input, isVisible, isReadOnly }
      }),
    [inputs, conditions, unregister],
  )
}
