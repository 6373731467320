import { css } from 'styled-components'

import jaguarWebWoffBold from './assets/fonts/JaguarModernWeb-Bold.woff2'
import jaguarWebWoff from './assets/fonts/JaguarModernWeb-Regular.woff2'
import proximaNovaWoff from './assets/fonts/ProximaNova-Regular.woff'
import proximaNovaWoffSemi from './assets/fonts/ProximaNova-Semibold.woff'
import UKNumberPlateEot from './assets/fonts/uknumberplate/UKNumberPlate.eot'
import UKNumberPlateWoff2 from './assets/fonts/uknumberplate/UKNumberPlate.woff2'

const fonts = css`
  @font-face {
    font-family: 'Jaguar Modern';
    src: url('${jaguarWebWoff as never}') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Jaguar Modern';
    src: url('${jaguarWebWoffBold as never}') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Proxima Nova';
    src: url('${proximaNovaWoff as never}') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Proxima Nova';
    src: url('${proximaNovaWoffSemi as never}') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'UKLicencePlate';
    src: url('${UKNumberPlateEot as never}') format('eot'),
      url('${UKNumberPlateEot as never}') format('eot');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'UKLicencePlate';
    src: url('${UKNumberPlateWoff2 as never}') format('woff2'),
      url('${UKNumberPlateWoff2 as never}') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }
`

export default fonts
