import deepEqual from 'deep-equal'

import {
  AdditionalProfileDisclosureChange,
  Data,
  getCurrentQuote,
  getInsuredProfile,
  PolicyChangeType,
  Profile,
  Proposal,
  ProposalAdjustmentRequest,
} from '../../../../domain'
import {
  AdjustmentInputConfig,
  ChangeType,
  ProfileChangeConfig,
} from '../../../../state/configuration'
import { toUpdates } from '../../helpers'

const toAdditionalProfileDisclosureReducer =
  (data: Data, { profileCode }: Profile) =>
  (
    proposalAdjustment: ProposalAdjustmentRequest,
    input: AdjustmentInputConfig<ProfileChangeConfig>,
  ): ProposalAdjustmentRequest => {
    const changes: AdditionalProfileDisclosureChange[] = toUpdates(input, data).map(
      (update) => ({
        changeType: PolicyChangeType.ADDITIONAL_PROFILE_DISCLOSURE_CHANGE,
        profileCode,
        update,
      }),
    )

    return {
      ...proposalAdjustment,
      changes: [...proposalAdjustment.changes, ...changes],
    }
  }

export const toAdditionalProfileProposalAdjustment =
  (inputs: AdjustmentInputConfig[], proposal: Proposal, profile: Profile) =>
  (existingData: Data, data: Data): ProposalAdjustmentRequest => {
    const quote = getCurrentQuote(proposal)
    const profileDisclosureReducer = toAdditionalProfileDisclosureReducer(data, profile)

    return inputs
      .filter((input) => !deepEqual(existingData[input.name], data[input.name]))
      .reduce(
        (proposalAdjustment: ProposalAdjustmentRequest, input) => {
          switch (input.adjustment.changeType) {
            case ChangeType.PROFILE_DISCLOSURE:
              return profileDisclosureReducer(
                proposalAdjustment,
                input as AdjustmentInputConfig<ProfileChangeConfig>,
              )
            default:
              throw new Error(
                `AdjustmentChangeType ${input.adjustment.changeType} not supported by this`,
              )
          }
        },
        {
          changes: !getInsuredProfile(quote, profile.profileCode)
            ? [
                {
                  changeType: PolicyChangeType.ADDITIONAL_PROFILE_CREATE_CHANGE,
                  profileCode: profile.profileCode,
                },
              ]
            : [],
        },
      )
  }
