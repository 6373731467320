import { Profile } from '../../../../domain'
import { ChangeConfig, ChangeType } from '../../../../state/configuration'
import { toData } from '../../helpers'

export const toAdditionalProfileProposalData =
  ({ data }: Profile) =>
  (adjustment: ChangeConfig) => {
    switch (adjustment.changeType) {
      case ChangeType.PROFILE_DISCLOSURE:
        return toData(data, adjustment.update)
      default:
        throw new Error(
          `AdjustmentChangeType ${adjustment.changeType} not supported for additional profiles`,
        )
    }
  }
