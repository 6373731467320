import { Box, BoxProps, Flex, Heading, mdBumps, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppPath } from '../../application/AppPath'
import { getFullName } from '../../domain'
import { useAuth } from '../../hooks/auth'
import { useErrorHandlingAsyncCallback } from '../../hooks/errors'
import { TKey, useWriskTranslation } from '../../infrastructure/internationalisation'
import { useAnonymous } from '../../state'
import { getAccountInputs, useConfig } from '../../state/configuration'
import { MenuAnchor } from './MenuAnchor'

const tKey = TKey('components.main-menu')

export interface MainMenuProps {
  onDismiss: () => void
  user: {
    username: string
    firstName: string
    lastName: string
  }
}

const MenuHeader: FunctionComponent<BoxProps> = ({ children, ...props }) => (
  <Box width={1} borderBottomWidth={1} px={mdBumps} py={5} {...props}>
    {children}
  </Box>
)

export const MainMenu: FunctionComponent<MainMenuProps> = ({ user, onDismiss }) => {
  const { t } = useWriskTranslation()

  const navigate = useNavigate()
  const auth = useAuth()
  const anonymous = useAnonymous()
  const accountInputs = useConfig(getAccountInputs)

  const onDashboardClick = useCallback(() => {
    onDismiss()
    navigate(AppPath.HOME)
  }, [navigate, onDismiss])

  const onAccountClick = useCallback(() => {
    onDismiss()
    navigate(AppPath.ACCOUNT)
  }, [navigate, onDismiss])

  const onSignOut = useErrorHandlingAsyncCallback(async () => {
    onDismiss()
    await anonymous.refresh()
    await auth.signOut()
  })

  return (
    <Flex width={1} variant='raised' flexDirection='column' alignItems='flex-start'>
      <MenuHeader>
        <Heading>{getFullName(user)}</Heading>
        <Typo typoSize='sm' color='bodySecondary'>
          {user.username}
        </Typo>
      </MenuHeader>
      <Box width={1} my={2}>
        <MenuAnchor id='dashboardAction' onClick={onDashboardClick}>
          <Typo typoSize='sm'>{t(tKey('dashboard'))}</Typo>
        </MenuAnchor>
        {Boolean(accountInputs.length) && (
          <MenuAnchor id='accountAction' onClick={onAccountClick}>
            <Typo typoSize='sm'>{t(tKey('account'))}</Typo>
          </MenuAnchor>
        )}
      </Box>
      <Box width={1} py={2} borderTopWidth={1}>
        <MenuAnchor id='signOutAction' onClick={onSignOut.execute}>
          <Typo typoSize='sm' color='textCritical'>
            {t(tKey('sign-out'))}
          </Typo>
        </MenuAnchor>
      </Box>
    </Flex>
  )
}
