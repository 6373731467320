import { Grid } from '@wrisk/ui-components'
import React from 'react'

import { IncludedCover } from '../../../hooks/covers'
import { IncludedCoverItem } from './IncludedCoverItem'

interface IncludedCoverItemsProps {
  includedCovers: IncludedCover[]
  contentBaseUrl: string
}

export const IncludedCoverItems: React.FC<IncludedCoverItemsProps> = ({
  includedCovers,
  contentBaseUrl,
}) => {
  const includedCoverItems = includedCovers.map((cover) => (
    <IncludedCoverItem
      key={cover.coverCode}
      width={1}
      cover={cover}
      contentBaseUrl={contentBaseUrl}
    />
  ))

  return (
    <Grid
      width={1}
      p={2}
      gridTemplateColumns={['1fr', '1fr', '1fr 1fr']}
      gridTemplateRows='auto'
      alignItems='stretch'
    >
      {includedCoverItems}
    </Grid>
  )
}
