import { Flex, mdBumps, Typo } from '@wrisk/ui-components'
import React from 'react'

import { Excess } from '../../../../domain/types/excess'
import {
  tExcesses,
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'

const tKey = TKey('components.main-excess')

export const MainExcessesDifferentValues: React.FC<{ excesses: Excess[] }> = ({
  excesses,
}) => {
  const { t } = useWriskTranslation()

  return (
    <Flex
      p={mdBumps}
      variant='raised'
      flexDirection='column'
      alignItems='flex-start'
      width={1}
    >
      <Typo typoSize='xs'>{t(tKey('header'))}</Typo>
      {excesses.map((it) => (
        <Flex key={it.excessCategory} justifyContent='space-between' width={1} mt={1}>
          <Typo>{t(tExcesses(it.excessCategory, 'name'))}</Typo>
          <Typo>{t(tFormats('currency.short'), { amount: it.excess })}</Typo>
        </Flex>
      ))}
    </Flex>
  )
}
