import {
  Header,
  HeaderTitle,
  pageEntranceSpringValues,
  xxlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'
import { Navigate } from 'react-router-dom'
import { animated, useSpring } from 'react-spring'

import { getCurrentQuote, Proposal } from '../../../../../../domain'
import {
  useProposalAdjustment,
  useProposalFormData,
} from '../../../../../../hooks/adjustments/proposal'
import {
  TExtends,
  TKey,
  useWriskTranslation,
} from '../../../../../../infrastructure/internationalisation'
import {
  PromotionConfig,
  PromotionDisclosureConfig,
} from '../../../../../../state/configuration'
import { AppPath } from '../../../../../AppPath'
import { FormErrors } from '../../../../../formBuilder'
import { PromotionBanner } from '../../../../../organisms/promotion'
import { useProposal } from '../../../proposalContext'

export interface PromotionSectionProps {
  section: PromotionDisclosureConfig
  onComplete: (proposal: Proposal) => void
}

const tKey = TKey('pages.disclosure')

const Content: FunctionComponent<
  PromotionSectionProps & { promotionConfig: PromotionConfig }
> = ({ section, onComplete, promotionConfig }) => {
  const { t } = useWriskTranslation()
  const spring = useSpring(pageEntranceSpringValues)

  const { proposal } = useProposal()

  const quote = getCurrentQuote(proposal)

  const data = useProposalFormData(promotionConfig.inputs)
  const onSubmitAdjustment = useProposalAdjustment(
    promotionConfig.inputs,
    false,
    onComplete,
  )

  const onSkip = useCallback(() => onComplete(proposal), [onComplete, proposal])

  return (
    <animated.div style={spring}>
      <Header>
        <HeaderTitle>{t(tKey('headers', section.id))}</HeaderTitle>
      </Header>

      <PromotionBanner
        tKey={TExtends(tKey, 'promotion-banner')}
        quote={quote}
        variant='narrow'
        mb={xxlBumps}
      />

      <FormErrors
        id={section.id}
        key={section.id}
        data={data}
        inputs={promotionConfig.inputs}
        onSubmit={onSubmitAdjustment.execute}
        onSkip={onSkip}
        loading={onSubmitAdjustment.loading}
        tKey={TExtends(tKey, 'form')}
        t={t}
      />
    </animated.div>
  )
}

export const PromotionSection: FunctionComponent<PromotionSectionProps> = ({
  section,
  onComplete,
}) => {
  const { proposal } = useProposal()

  const { promoCode } = getCurrentQuote(proposal)

  const promotionConfig = promoCode && section.promotions[promoCode]

  return promotionConfig ? (
    <Content
      section={section}
      onComplete={onComplete}
      promotionConfig={promotionConfig}
    />
  ) : (
    <Navigate to={AppPath.HOME} replace />
  )
}
