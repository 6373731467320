import css, { ResponsiveStyleValue } from '@styled-system/css'
import { animated } from 'react-spring'
import styled from 'styled-components'
import { variant } from 'styled-system'

export type AnimatedMenuProperty = 'default' | 'manageQuote'

export interface AnimatedMenuProps {
  variant?: ResponsiveStyleValue<AnimatedMenuProperty>
}

export const AnimatedMenu = styled(animated.div)<AnimatedMenuProps>(
  css({
    bg: 'chalk',
    borderRadius: 1,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    zIndex: 999,
  }),
  variant({
    variants: {
      default: {
        mt: 2,
        right: 2,
        width: '304px',
      },
      manageQuote: {
        mt: 8,
        alignItems: 'flex-start',
        width: 'auto',
        boxShadow: 'popupMenu',
      },
    },
  }),
)

AnimatedMenu.defaultProps = {
  variant: 'default',
}
