import React, { FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  useProposalAdjustment,
  useProposalFormData,
} from '../../../../hooks/adjustments/proposal'
import {
  TExtends,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { Page, useUpButton } from '../../../../infrastructure/routing'
import { QuoteManagementConfig } from '../../../../state/configuration'
import { FormErrors } from '../../../formBuilder'
import { FullPage } from '../../../templates'

export interface QuoteManagementPageProps {
  config: QuoteManagementConfig
  parent: Page
}

const tKey = TKey('pages.disclosure')

export const RenewalDisclosurePage: FunctionComponent<QuoteManagementPageProps> = ({
  config,
  parent,
}) => {
  const { t } = useWriskTranslation()

  const navigate = useNavigate()
  const upButton = useUpButton(parent)

  const data = useProposalFormData(config.inputs)

  const onSubmit = useProposalAdjustment(config.inputs, false, () => navigate(parent.url))

  return (
    <FullPage header={t(tKey('headers', config.id))} upButton={upButton}>
      <FormErrors
        id='quote-management'
        data={data}
        inputs={config.inputs}
        onSubmit={onSubmit.execute}
        loading={onSubmit.loading}
        tKey={TExtends(tKey, 'form')}
        t={t}
      />
    </FullPage>
  )
}
