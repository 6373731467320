import { LinkAnchor, LinkAnchorProps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  tDefaults,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

export const BusinessTermsLink: FunctionComponent<LinkAnchorProps> = (props) => {
  const { t } = useWriskTranslation()

  return (
    <LinkAnchor
      fontFamily='body'
      fontWeight='normal'
      textTransform='lowercase'
      color='inherit'
      href={t<string>('links.privacy-policy')}
      target='_blank'
      {...props}
    >
      {t(tDefaults('documents', 'privacy-policy'))}
    </LinkAnchor>
  )
}
