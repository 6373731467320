import { Box, ModalHeader, TrustPilotAnchor, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { Chat } from '../Chat'

const tKey = TKey('components.feedback-modal-trust-pilot')

export const FeedbackModalTrustPilot: FunctionComponent = () => {
  const { t } = useWriskTranslation()

  return (
    <Box>
      <ModalHeader header={t(tKey('header'))} />
      <Typo>{t(tKey('text'))}</Typo>
      <TrustPilotAnchor mt={4}>{t(tKey('actions.review'))}</TrustPilotAnchor>
      <Typo mt={4}>
        <Trans
          t={t}
          i18nKey={tKey('further-questions')}
          components={{
            Chat: <Chat />,
          }}
        />
      </Typo>
    </Box>
  )
}
