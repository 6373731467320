import css from '@styled-system/css'
import styled from 'styled-components'
import {
  borders,
  BordersProps,
  color,
  ColorProps,
  compose,
  layout,
  LayoutProps,
  ResponsiveValue,
  space,
  SpaceProps,
  variant,
} from 'styled-system'

export type NavigationProperty = 'default'

export interface NavigationProps
  extends LayoutProps,
    SpaceProps,
    ColorProps,
    BordersProps {
  variant?: ResponsiveValue<NavigationProperty>
}

export const Navigation = styled.div<NavigationProps>(
  css({
    width: '100%',
    position: 'relative',
    bg: 'transparent',
    borderWidth: 0,
    borderStyle: 'solid',
    borderColor: 'border',
  }),
  variant({
    scale: 'Navigation',
    variants: {
      default: {},
    },
  }),
  compose(layout, space, color, borders),
)

Navigation.defaultProps = {
  variant: 'default',
}
