import { Flex, mdBumps, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { ProductConfig } from '../../../state/configuration'

const tKey = TKey('components.metered-pay-today')

export interface MeteredPayTodayProps {
  premiumAmount: number
  product: ProductConfig
}

export const MeteredPayToday: FunctionComponent<MeteredPayTodayProps> = ({
  premiumAmount,
  product,
}) => {
  const { t } = useWriskTranslation()

  const coreAssetType = product.assetTypes.find((it) => it.core)
  if (coreAssetType === undefined) {
    throw new Error('Expected a core asset to be defined.')
  }

  return (
    <Flex width={1} p={4} borderWidth={1} borderRadius={1} mt={5}>
      <Flex flexDirection='column' alignItems='flex-start' mr={mdBumps}>
        <Typo typoSize='sm'>{t(tKey('header'))}</Typo>
        <Typo typoSize='xs' color='bodySecondary'>
          {t(tKey('subheader'))}
        </Typo>
      </Flex>
      <Typo fontWeight='bold'>
        {t(tFormats('currency.long'), { amount: premiumAmount })}
      </Typo>
    </Flex>
  )
}
