import { Box, ModalHeader, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { Chat } from '../Chat'

const tKey = TKey('components.feedback-modal-confirmation')

export const FeedbackModalConfirmation: FunctionComponent = () => {
  const { t } = useWriskTranslation()

  return (
    <Box>
      <ModalHeader header={t(tKey('header'))} />
      <Typo>
        <Trans
          t={t}
          i18nKey={tKey('text')}
          components={{
            Chat: <Chat />,
          }}
        />
      </Typo>
    </Box>
  )
}
