import {
  Box,
  Flex,
  mdBumps,
  PaymentItemCell,
  PaymentTotalCell,
  smBumps,
  Status,
  Typo,
  xsBumps,
} from '@wrisk/ui-components'
import { negate } from 'lodash'
import React, { FunctionComponent } from 'react'

import {
  CustomerStatementLineItem,
  CustomerStatementStatus,
  CustomerStatementSummary,
  isJournalLineItem,
  isPendingLineItem,
  LineItemStatus,
} from '../../../domain'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { toDateTime } from '../../../util/date'

type PaymentsProps = {
  payments: CustomerStatementLineItem[]
  statementSummary: CustomerStatementSummary
}

const tKey = TKey('components.statement-modal.payments')

export const Payments: FunctionComponent<PaymentsProps> = ({
  payments,
  statementSummary,
}) => {
  const { t } = useWriskTranslation()

  const { amountOutstanding, carriedForwardBalance, status, paymentDueAt, final } =
    statementSummary

  const nonPendingPayments = payments.filter(negate(isPendingLineItem))

  const carriedForwardBalanceItem =
    carriedForwardBalance !== 0 ? (
      <Flex width={1} px={mdBumps} py={xsBumps}>
        <Typo typoSize='sm'>
          {t<string>(
            tKey(
              'carried-forward-balance',
              carriedForwardBalance > 0 ? 'credit' : 'debit',
            ),
          )}
        </Typo>
        <Typo typoSize='sm'>
          {t<string>(tFormats('currency.long'), {
            amount: carriedForwardBalance / 100,
          })}
        </Typo>
      </Flex>
    ) : null

  const isPendingStatusWithOutstandingBalance =
    status === CustomerStatementStatus.PENDING && amountOutstanding > 0

  const pendingItemContent =
    isPendingStatusWithOutstandingBalance && final
      ? t(tKey('pending-final-payment'))
      : isPendingStatusWithOutstandingBalance && !final
      ? t(tKey('pending-payment'), {
          paymentDueAt: t(tFormats('date.medium'), {
            value: toDateTime(paymentDueAt),
          }),
        })
      : final && amountOutstanding < 0
      ? t(tKey('pending-refund'), {
          amount: t(tFormats('currency.long'), {
            amount: -amountOutstanding / 100,
          }),
        })
      : null

  const pendingItem = pendingItemContent ? (
    <Flex width={1} px={mdBumps} py={xsBumps}>
      <Typo typoSize='sm'>{pendingItemContent}</Typo>
    </Flex>
  ) : null

  const showPayments =
    Boolean(nonPendingPayments.length) ||
    Boolean(carriedForwardBalanceItem) ||
    Boolean(pendingItem)

  return showPayments ? (
    <Box variant='raised' mt={mdBumps}>
      <Box px={mdBumps} py={smBumps} borderBottomWidth={1}>
        <Typo fontWeight='bold'>{t(tKey('header'))}</Typo>
      </Box>

      <Box borderBottomWidth={1} py={2}>
        {nonPendingPayments.map((payment, index) => {
          const prefix = t(tKey('prefixes', payment.type), {
            defaultValue: null,
          })

          const description = isJournalLineItem(payment)
            ? t(tKey('journal-descriptions', payment.type))
            : [
                prefix,
                payment.description ?? t(tKey('default-descriptions', payment.subType)),
              ]
                .filter(Boolean)
                .join(': ')

          const amount =
            payment.status === LineItemStatus.FAILED ? (
              <Status typoSize='xs' variant='critical'>
                {t(tKey('failed-payment.status'))}
              </Status>
            ) : (
              t(tFormats('currency.long'), {
                amount: payment.amount / 100,
              })
            )

          return (
            <PaymentItemCell
              key={index}
              date={t<string>(tFormats('date.medium'), {
                value: toDateTime(payment.effectiveAt),
              })}
              description={description}
              amount={amount}
            />
          )
        })}
        {carriedForwardBalanceItem}
        {pendingItem}
      </Box>

      <PaymentTotalCell
        header={t<string>(tKey('total'))}
        premium={t<string>(tFormats('currency.long'), {
          amount: amountOutstanding / 100,
        })}
      />
    </Box>
  ) : null
}
