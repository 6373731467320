import { AxiosInstance } from 'axios'

import { StripeSession } from '../../../domain'
import { withGenericErrorHandling } from '../errors'

export interface PaymentCardClient {
  stripeSettleSession: (params: StripeSettleRequest) => Promise<StripeSession>
  stripeCheckoutSession: (params: StripeCheckoutRequest) => Promise<StripeSession>
  stripeSetupSession: (params: StripeSetupRequest) => Promise<StripeSession>
}

interface LineItem {
  name: string
  description?: string
  imageUrl: string | undefined
}

export interface StripeSetupRequest {
  clientReference?: string
  successUrl: string
  cancelUrl: string
}

export interface StripeCheckoutRequest {
  paymentId: string
  successUrl: string
  cancelUrl: string
  lineItem: LineItem
}

export interface StripeSettleRequest {
  accountId: string
  successUrl: string
  cancelUrl: string
  lineItem: LineItem
}

export const createPaymentCardClient = (
  getInstance: () => Promise<AxiosInstance>,
): PaymentCardClient => ({
  stripeCheckoutSession: (params) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.post<StripeSession>('/stripe/checkout', params)
      return response.data
    }),
  stripeSettleSession: (params) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.post<StripeSession>('/stripe/settle', params)
      return response.data
    }),
  stripeSetupSession: (params) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.post<StripeSession>('/stripe/setup', params)
      return response.data
    }),
})
